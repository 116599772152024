import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Link, useParams } from "react-router-dom";
import Datatable from "../../Components/Datatable/Datatable";
import { getLeads, unSetLead } from "./ApiCalls";
import { Alert } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useQuery } from "react-query";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

const TodaysLeads = () => {

  const page_id = useParams(); 

  const [myPage, setMyPage] = useState(0);
  const handleCurrentPage = (currentpage) => {
    setMyPage(currentpage);
  }
  
  React.useEffect(() => { 
    if(page_id.pageId && page_id.pageId !== 'undefined'){
      let pid = page_id.pageId.split('=')[1];
      setMyPage(parseInt(pid));
    }
  }, [page_id]); 

  // delete confirm box
  const [open, setOpen] = useState(false);
  const [leadId, setLeadId] = useState('');
  const handleClickOpen = (lid) => {
    setOpen(true);
    setLeadId(lid);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [multipleIds, setMultipleIds] = useState([]);

  const query = useQuery("missedleads", () => {
    return getLeads()
  },{})

  if(query.isLoading){
    return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
  }
  if(query.isError){
    return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
  }

  const handleCallback = (childData) =>{
    if(childData === 'delete'){
      console.log('delete')
      deleteLead(multipleIds)
    }else{
      setMultipleIds(childData)
      console.log(childData)
    }
  }

  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  // const redirect = () =>{
  //   navigate("/leads");
  // }

  const deleteLead = async(lids) => {
    unSetLead(lids)
    .then((response) => {
      if(response.data === 'lead deleted'){
        handleClose();
        notify("Leads Deleted Successfully");
      }
      console.log(response)
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const columns = [
    {
      name: "Actions",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 10000
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 10000
          }
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Box sx={{ display: "flex" }}>
              <Tooltip
                title="Quick Edit"
                component={Link}
                to={`/quick-edit/${query.data[dataIndex].id}/mypage=${myPage}`} 
              >
                <IconButton><AutoFixHighIcon sx={{ fontSize: "20px" }} /></IconButton>
              </Tooltip>
              <Tooltip
                title="Edit"
                component={Link}
                to={`/edit-lead/${query.data[dataIndex].id}/mypage=${myPage}`}
                // onClick={() => {
                //   alert(query.data[dataIndex].id);
                // }}
              >
                <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
              </Tooltip>
              <Tooltip
                title="View"
                component={Link}
                to={`/view-lead/${query.data[dataIndex].id}/mypage=${myPage}`}
              >
                <IconButton><VisibilityIcon sx={{ fontSize: "20px" }} /></IconButton>
              </Tooltip>
              {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin'  ? 
              <Tooltip
                title="Delete"
                component="label"
                onClick={() => handleClickOpen(query.data[dataIndex].id)} 
              ><IconButton><DeleteIcon sx={{ fontSize: "20px" }} /></IconButton>
              </Tooltip>
              : ''} 
            </Box>
            // <Box sx={{ display: "flex" }}>
            //   <IconButton
            //     color="primary"
            //     aria-label="edit"
            //     component="label"
            //     onClick={() => {
            //       alert(data[dataIndex].id);
            //     }}
            //   >
            //     <EditIcon />
            //   </IconButton>
            //   <IconButton
            //     color="error"
            //     aria-label="delete"
            //     component="label"
            //     onClick={() => {
            //       alert(data[dataIndex].id);
            //     }}
            //   >
            //     <DeleteIcon />
            //   </IconButton>
            // </Box>
          );
        },
      },
    },
    {
      name: "name",
      label: "Lead Name",
    },
    {
      name: "mobile",
      label: "Mobile 1",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "pname",
      label: "Project Name",
    },
    {
      name: "lead_status",
      label: "Lead Status",
    },
    {
      name: "lead_date",
      label: "Lead Date",
    },
    {
      name: "source",
      label: "Source",
    },
    {
      name: "city",
      label: "City",
    },
    {
      name: "locality",
      label: "Locality",
    },
    {
      name: "assigned_telecaller",
      label: "Assigned Telecaller",
    },
    {
      name: "assigned_sales_manager",
      label: "Assigned Sales Manager",
    },
    {
      name: "assigned_team_leader",
      label: "Team Leader",
    },
    {
      name: "assigned_branch_admin",
      label: "Assigned Branch Admin",
    },
    {
      name: "posted_by",
      label: "Posted By",
    },
    {
      name: "id",
      label: "Id",
      options: {
        // display: false,
      },
    }
  ];

  return (
    <>
     {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' || Cookies.get('role') === 'Team Leader' || Cookies.get('role') === 'Sales Manager'  ? 
     <Breadcrumb 
        PageName="Present Leads To Call" 
        AssignButton={[multipleIds.length !== 0 ? true : false, 'Assign Leads', `/assign-leads-to/${multipleIds}/mypage=${myPage}`, <AddIcon />]}
      />  
      :
      <Breadcrumb 
        PageName="Present Leads To Call"  
      /> 
      }
      
      <Datatable sendDataToParent = {handleCallback} TabelTitle="Total Lead" data={query.data === 'No Data Found' ? [] : query.data} columns={columns} currentPage = {myPage} changeCurrentPage = {handleCurrentPage}  />

      {/* delete confirm */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => deleteLead(leadId)} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
      
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
export default TodaysLeads;
