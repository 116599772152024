import React, { useState, useEffect, useRef } from "react";
import {useNavigate} from 'react-router-dom';
import { Box, Typography, Grid, Button } from "@mui/material";
import {
    CustomInputField,
    CustomSelectFiled,
    CustomFormGroup,
} from '../../Components/Common';
import {
    UserType,
    BranchLocation,
} from '../../Data/Data';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import { getUsers, setUser, getCrmDetails } from "./ApiCalls";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Alert from "@mui/material/Alert";

const AddUser = () => {

    const navigate = useNavigate();

    const [urole, setURole] = useState();
    const [utype, setUtype] = useState();
    const [usersList, setUsersList] = useState([]);  
    const [noOfUsersLimit, setNoOfUsersLimit] = useState([]);  
    const no_of_branch_admin = useRef(0)
    const no_of_teamleader = useRef(0)
    const no_of_salesmanager = useRef(0)
    const no_of_telecaller = useRef(0)

    const no_of_created_telecaller = useRef(0)
    const no_of_created_salesmanager = useRef(0)
    const no_of_created_teamleader = useRef(0)
    const no_of_created_branch_admin = useRef(0)
    const total_no_of_users_created = useRef(0)

    function groupBy(objectArray, property) {
        return objectArray.reduce((acc, obj) => {
           const key = obj[property];
           if (!acc[key]) {
              acc[key] = [];
           }
           acc[key].push(obj);
           return acc;
        }, {});
     }

    useEffect(() => {
        getUsers().then((response)=>{
            
            setUsersList(groupBy(response.data.map((users)=>({label: users.username, value: users.u_id, urole:users.urole})), 'urole'))
            total_no_of_users_created.current = response.data.length;
        }).catch((err)=>{
            console.log(err);
        })

        getCrmDetails().then((response)=>{
            setNoOfUsersLimit(response.data[0])
        }).catch((err)=>{
            console.log(err);
        })
    }, []); 


    const UserRole = [
        { value: 'Team Leader', label: 'Team Leader', isdisabled: noOfUsersLimit['team_leader'] === 'No' || no_of_teamleader.current - no_of_created_teamleader.current === 0 ?true:false },
        { value: 'Sales Manager', label: 'Sales Manager', isdisabled: noOfUsersLimit['sales_manager'] === 'No' || no_of_salesmanager.current - no_of_created_salesmanager.current === 0 ?true:false},
        { value: 'Tele Caller', label: 'Tele Caller', isdisabled: noOfUsersLimit['tele_caller'] === 'No' || no_of_telecaller.current - no_of_created_telecaller.current === 0 ?true: false },
        { value: 'Account', label: 'Account' },
        { value: 'HR', label: 'HR' },
    ];

    const UserRoleAdmin = [
        { value: 'Admin', label: 'Admin'},
        { value: 'Branch Admin', label: 'Branch Admin', isdisabled: noOfUsersLimit['branch'] === 'Yes'?false:true },
    ];
         
    no_of_branch_admin.current = noOfUsersLimit.branch === 'Yes' ? 
    noOfUsersLimit.no_of_branch : 0
    no_of_teamleader.current = noOfUsersLimit.team_leader === 'Yes' ? 
    noOfUsersLimit.no_of_tl : 0
    no_of_salesmanager.current = noOfUsersLimit.sales_manager === 'Yes' ?noOfUsersLimit.no_of_sm : 0
    no_of_telecaller.current = noOfUsersLimit.tele_caller  === 'Yes' ? noOfUsersLimit.no_of_tc : 0

    no_of_created_telecaller.current = usersList.hasOwnProperty('Tele Caller') ? usersList['Tele Caller'].length : 0
    no_of_created_salesmanager.current = usersList.hasOwnProperty('Sales Manager') ? usersList['Sales Manager'].length : 0
    no_of_created_teamleader.current = usersList.hasOwnProperty('Team Leader') ? usersList['Team Leader'].length : 0
    no_of_created_branch_admin.current = usersList.hasOwnProperty('Branch Admin') ? usersList['Branch Admin'].length : 0

    const notify = (msg) => toast(msg, {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    
    const redirect = () =>{
        navigate("/all-users");
    }

    // const handleSubmit = () => async(event) => {
    //     alert('')
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     setUser(data)
    //     .then((response) => {
    //         console.log('hi')
    //         alert('hi')
    //         // notify("User Created Successfully")
    //         // setTimeout(() => redirect(), 3000);
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    //  }

    const handleSubmit = (event) => {
        event.preventDefault();
        notify("User Created Successfully")
        const data = new FormData(event.currentTarget);
        setUser(data)
        .then((response) => { 
            // console.log(response)
        })
        .catch((err) => {
            console.log(err)
        });

        setTimeout(() => redirect(), 1000);

        //console.log(data)
        // setUser(data)
        // .then((response) => {
        //     notify("Leads Assigned Successfully")
        //     setTimeout(() => redirect(), 3000);
        // })
        // .catch((err) => {
        //   console.log(err);
        // });
    };

    return (
        <>
            <Breadcrumb PageName="Add User" />

            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            {total_no_of_users_created.current === noOfUsersLimit.total_users ? <Alert severity="info">Your total number of user creation limit exhausted, Please contact your CRM service provider</Alert> :

            <Box className="custom-card">
                <Typography variant="h4" className="custom-card-head">
                    <p>
                        <span>
                            You can create {no_of_teamleader.current - no_of_created_teamleader.current} Team Leader, {no_of_salesmanager.current - no_of_created_salesmanager.current} Sales Manager & {no_of_telecaller.current - no_of_created_telecaller.current} Tele Caller
                        </span>
                        <span style={{right: "2px",position: "absolute",marginRight: "25px"}}>
                            Total User Limit:- {noOfUsersLimit.total_users}
                        </span>
                    </p>
                </Typography>
               
                <Box p={3} component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2} mb={1}>
                        <CustomFormGroup
                            formlabel="Username"
                            star="*"
                            FormField={
                                <CustomInputField
                                    type="text"
                                    name="username"
                                    placeholder="Enter Username..."
                                    required="required"
                                />
                            }
                        />

                        <CustomFormGroup
                            formlabel="Password"
                            star="*"
                            FormField={
                                <CustomInputField
                                    type="password"
                                    name="password"
                                    placeholder="Enter Password"
                                    required="required"
                                />
                            }
                        />

                        <CustomFormGroup
                            formlabel="User Type"
                            star="*"
                            FormField={
                                <CustomSelectFiled name="user_type" options={UserType} onChange={(event) => setUtype(event.value)} required="required" />
                            }
                        />

                        <CustomFormGroup
                            formlabel="User Role"
                            star="*"
                            FormField={
                                <CustomSelectFiled name = "user_role" options={utype === 'Admin' ? UserRoleAdmin : UserRole} onChange={(event) => setURole(event.value)} optionDisbaled = {(option) => option.isdisabled} required="required"/>
                            }
                        />

                        {urole === "Admin" ||  urole === "Branch Admin"?
                            <>
                                <CustomFormGroup
                                    formlabel="Branch Location"
                                    star="*"
                                    FormField={
                                        <CustomSelectFiled 
                                            name="branch_location" 
                                            options={BranchLocation} 
                                            onChange={(event) => console.log(event.value)}
                                            required="required"
                                        />
                                    }
                                />
                            </>
                        : null}

                        {urole === "Sales Manager" ?
                            <>
                                <CustomFormGroup
                                    formlabel="Team Leader"
                                    star="*"
                                    FormField={
                                        <CustomSelectFiled name="team_leader" options={usersList['Team Leader']} required="required" />
                                    }
                                />
                            </>
                        : null}

                        {urole === "Tele Caller" ?
                            <>
                                <CustomFormGroup
                                    formlabel="Team Leader"
                                    star="*"
                                    FormField={
                                        <CustomSelectFiled name="team_leader" options={usersList['Team Leader']} required="required"/>
                                    }
                                />
                                <CustomFormGroup
                                    formlabel="Sales Manager"
                                    star="*"
                                    FormField={
                                        <CustomSelectFiled name="sales_manager" options={usersList['Sales Manager']} required="required"/>
                                    }
                                />
                            </>
                        : null}
                        
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={6} md={2}></Grid>
                        <Grid item xs={6} md={10}>
                            <Button type="submit" variant="contained" disableElevation>
                                Add User
                            </Button>      
                        </Grid>
                    </Grid>
                    
                </Box>
            </Box>
            }
        </>
    );
};

export default AddUser;