import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
  
function createData(name, dsa, maths, dbms, networking) {
    return { name, dsa, maths, dbms, networking };
}
  
const rows = [
    createData("John", 80, 66, 76, 89),
    createData("Sandeep", 82, 83, 79, 98),
    createData("Raman", 85, 79, 80, 85),
    createData("Saini", 75, 67, 85, 78),
    createData("Virat", 90, 89, 84, 76),
    createData("Rohit", 86, 83, 95, 88),
    createData("Smriti", 92, 90, 89, 80),
    createData("Mandhana", 86, 88, 88, 89),
    createData("Deepti", 79, 86, 80, 88)
];
  
export default function BasicTable() {
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
  
    function handleChangePage(event, newpage) {
        setpg(newpage);
    }
  
    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
  
    return (
        <>
        <Paper>
            <h1 style={{ textAlign: "center", color: "green" }}>
                GeeksForGeeks
            </h1>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} 
                    aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Geek</TableCell>
                            <TableCell align="right">Dsa
                            </TableCell>
                            <TableCell align="right">Maths
                            </TableCell>
                            <TableCell align="right">Dbms
                            </TableCell>
                            <TableCell align="right">Networking
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(pg * rpg, pg * rpg + rpg).map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ "&:last-child td,&:last-child th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.dsa}
                                </TableCell>
                                <TableCell align="right">{row.maths}
                                </TableCell>
                                <TableCell align="right">{row.dbms}
                                </TableCell>
                                <TableCell align="right">{row.networking}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rpg}
                page={pg}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
        </>
    );
}