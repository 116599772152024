import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const getLeadsCount = async (LIMIT, page) =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/leads/getAllLeadsCount`);
        return responseCount.data[0].leadcount;
    }catch(err){
        return err;
    }
}

const getNonAssignLeadsCount = async (LIMIT, page) =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/desktop/assignnonassign/getallnonassignleadscount`);
        return responseCount.data[0].leadcount;
    }catch(err){
        return err;
    }
}

const getAssignLeadsCount = async (LIMIT, page) =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/desktop/assignnonassign/getallassignleadscount`);
        return responseCount.data[0].leadcount;
    }catch(err){
        return err;
    }
}

const getMissedLeadsCount = async (LIMIT, page) =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/followups/getMissedLeadCount`);
        return responseCount.data[0].leadcount;
    }catch(err){
        return err;
    }
}

const getPresentLeadsCount = async (LIMIT, page) =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/followups/getTodayLeadsCount`);
        return responseCount.data[0].leadcount;
    }catch(err){
        return err;
    }
}

export {getLeadsCount, getNonAssignLeadsCount, getAssignLeadsCount, getMissedLeadsCount, getPresentLeadsCount}