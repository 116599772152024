import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { FollowUp } from '../../Data/Data';
import {
  CustomSelectFiled,
  CustomInputField,
  CustomFormGroup,
  CustomTextareaField,
} from '../../Components/Common';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { getAllLeadStatusList, setLead, getLead, setQuickEditLead } from '../EditLead/ApiCalls';
import dayjs from "dayjs"; 

const QuickEdit = () => {

  const navigate = useNavigate();
  const lead_id = useParams(); 
  const [leadsDetails, setLeadsDetails] = useState('');
 
  const [follow_up, setFollowUp] = useState(); 
  const [leadStatus, setLeadStaus] = useState();
  const [leadstatuslist, setAllLeadStatusList] = React.useState([]); 
  const [comments, setComments] = React.useState([]);

  useEffect(() => {
   
    getAllLeadStatusList().then(response => {
      if (response.data === "No Data Found") {
        setAllLeadStatusList(response.data.data)
      } else {
        setAllLeadStatusList(response.data.map((leadstatuslist) => ({ value: leadstatuslist.status, label: leadstatuslist.status })))
      }
    }).catch(err => {
      console.log(err);
    });
   
    getLead(lead_id)
    .then((response) => { 
      setLeadsDetails(response.data[0]);
      setLeadStaus(response.data[0].status);
      setFollowUp(response.data[0].followup);
      if (response.data[0].comments){
      setComments(response.data[0].comments.split('~'))
      }
    })
    .catch((err) => {
      console.log(err);
    }); 
    
  }, [lead_id]);
    
  const handleChangeStatus = (event) => {
    if(event !== null){
      setLeadStaus(event.value);
    }
  };
 
  const handleEditLead = (event) => {
    event.preventDefault(); 
    const data = new FormData(event.currentTarget); 
    // comments.push(data.get('comments'))
    // let implode = "";
    //   if (comments !== null) {
    //     implode = comments?.map((item) => item).join("~");
    //   } else {
    //     implode = comments;
    //   }

      const usercomments = dayjs().format("MMM DD,YYYY h:mm A") + "= " + data.get("comments");
      setComments(arr => [...arr, usercomments]);
      comments.push(usercomments);
      let implode = "";
      if (comments !== null) {
        implode = comments?.map((item) => item).join("~");
      } else {
        implode = comments;
      }
    if(data.get("lead_status") === ''){
      alert('Please fill out required fields.')
    }else{
      setQuickEditLead(data, lead_id, implode)
      .then((response) => { 
        setTimeout(() => {
          alert("Lead is updated.");
          navigate("/total-leads/mypage="+lead_id.pageId.split('=')[1]);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  return (
    <>
      <Breadcrumb PageName="Quick Edit" BackButton={[true, 'Back', '/total-leads/mypage='+lead_id.pageId.split('=')[1], <ArrowBackIosOutlinedIcon />]} />

      <Box component="form" autoComplete="off" onSubmit={handleEditLead} method="post">
         
        <Box className="custom-card" mt={0}>
          <Typography variant="h4" className="custom-card-head">
            Client Status :-
          </Typography>
          <Box p={2}>
            <Grid container spacing={2} mb={2}>
              <CustomFormGroup
                formlabel="Lead Status"
                star="*"
                FormField={
                  <CustomSelectFiled 
                    name="lead_status" 
                    required={true} 
                    options={leadstatuslist}  
                    inputrole="Quick Edit"
                    defaultVal={leadStatus}
                    onChange={handleChangeStatus} 
                  />
                }
              />
              <CustomFormGroup
                formlabel="Follow Up"
                star="*"
                FormField={
                  <CustomSelectFiled
                    name="followup" 
                    required={true}
                    options={FollowUp}
                    inputrole="Quick Edit"
                    defaultVal={follow_up}
                    onChange={(event) => setFollowUp(event.value)}
                  />
                }
              />

              {follow_up === 'Yes' ? (
                <>
                  <CustomFormGroup
                    formlabel="Follow Up Date"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="datetime-local"
                        name="followup_dt"
                        required={true}
                        defaultVal={leadsDetails.followup_dt}
                      />
                    }
                  />
                </>
              ) : null} 
            </Grid>

            <Grid container spacing={2} >
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  Add Comment <span className="required-label"></span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={10}>
                <CustomTextareaField
                  name="comments"
                  placeholder="Enter Comments ...."
                  // defaultVal={leadsDetails.comments}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} my={.5}>
              <Grid item xs={6} md={2}></Grid>
              <Grid item xs={6} md={10}>
                <Button variant="contained" type="submit" >
                  Save Lead
                </Button>
              </Grid>
            </Grid>
            
          </Box>
        </Box>

      </Box>
    </>
  );
};

export default QuickEdit;