import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const getUsers = async() => {
    try{
      const response = await Axios.post(`${API_URL}/users/getUserList`);
      return response;
    }catch(err){
        return err;
    }
  };

export{getUsers}