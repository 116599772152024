import { useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import {
    Box,
    Typography,
    Grid,
    Button,
} from "@mui/material";
import {
    CustomMultiSelectFiled,
    CustomSelectFiled,
    CustomFormGroup,
} from "./../../Components/Common";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import "./AssignLeadForm.css";
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import {useParams } from "react-router-dom";
import { assignLead, getAssignUser } from "./ApiCalls";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AssignLeadForm = () => {
    const [urole, setURole] = useState();
    const [selectedAssignUser, setSelectedAssignUser] = useState('');
    const [usersList, setUsersList] = useState([]);  

    let lead_ids = [useParams()]; 
    const navigate = useNavigate();

    const notify = (msg) => toast(msg, {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const redirect = () =>{
        navigate("/total-leads");
    }

    const assignLeads = () => async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget); 
        console.log((data.get('user')).split(','))
        assignLead(lead_ids[0].leadId.split(','), (data.get('user')).split(','))
        .then((response) => {
            notify("Leads Assigned Successfully")
            setTimeout(() => redirect(), 3000);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    function groupBy(objectArray, property) {
        return objectArray.reduce((acc, obj) => {
           const key = obj[property];
           if (!acc[key]) {
              acc[key] = [];
           }
           acc[key].push(obj);
           return acc;
        }, {});
    }

    useEffect(() => {
        getAssignUser().then((response)=>{
            console.log(response)
            setUsersList(groupBy(response.data.map((users)=>({label: users.username, value: users.u_id, urole:users.urole})), 'urole'))
        }).catch((err)=>{
            console.log(err);
        })
    }, []); 

    const UserRole = [
        { value: "Team Leader", label: "Team Leader", isdisabled: usersList.hasOwnProperty('Team Leader')?false:true} ,
        { value: "Sales Manager", label: "Sales Manager", isdisabled: usersList.hasOwnProperty('Sales Manager')?false:true} ,
        { value: "Tele Caller", label: "Tele Caller", isdisabled: usersList.hasOwnProperty('Tele Caller')?false:true} ,
    ];
     
    return (
        <>
            <Breadcrumb PageName="Assign Lead" BackButton={[true, 'Back', '/total-leads', <ArrowBackIosOutlinedIcon />]} />
            <form onSubmit={assignLeads()}>
                <Box className="custom-card" >
                    <Typography variant="h4" className="custom-card-head">
                        Lead Selected : {lead_ids[0].leadId.split(',').length}
                    </Typography>
                    <Box p={3}>
                        <Grid container spacing={2} mb={2}>
                            <CustomFormGroup
                                formlabel="User Role"
                                FormField={
                                    <CustomSelectFiled
                                        options={UserRole}
                                        name="urole"
                                        onChange={(event) => setURole(event.value)}
                                        optionDisbaled = {(option) => option.isdisabled}
                                    />
                                }
                            />

                         {urole === "Team Leader" ?
                            <>
                               <CustomFormGroup
                                formlabel="Team Leader"
                                FormField={
                                    // <CustomSelectFiled
                                    //     options={usersList['Team Leader']}
                                    //     name="team_leader"
                                    //     onChange={(event) => setSelectedAssignUser(event.value)}
                                    // />
                                    <CustomMultiSelectFiled
                                        options={usersList['Team Leader']}
                                        name="user"
                                        onChange={(event) => setSelectedAssignUser(event.value)}
                                        inputrole='assign user'
                                    />
                                }
                               />
                           </>
                            : null}

                            {urole === "Sales Manager" ?
                                <CustomFormGroup
                                    formlabel="Sales Manager"
                                    FormField={
                                        // <CustomSelectFiled
                                        //     options={usersList['Sales Manager']}
                                        //     Selectlabel="Sales Manager"
                                        //     name="sales_manager"
                                        //     onChange={(event) => setSelectedAssignUser(event.value)}
                                        // />
                                        <CustomMultiSelectFiled
                                            options={usersList['Sales Manager']}
                                            name="user"
                                            Selectlabel="Sales Manager"
                                            onChange={(event) => setSelectedAssignUser(event.value)}
                                            inputrole='assign user'
                                        />
                                    }
                                />
                            : null}

                            {urole === "Tele Caller" ?
                                <CustomFormGroup
                                    formlabel="Tele Caller"
                                    FormField={
                                        // <CustomSelectFiled
                                        //     options={usersList['Tele Caller']}
                                        //     Selectlabel="Tele Caller"
                                        //     name="tele_caller"
                                        //     onChange={(event) => setSelectedAssignUser(event.value)}
                                        // />
                                        <CustomMultiSelectFiled
                                            options={usersList['Tele Caller']}
                                            name="user"
                                            Selectlabel="Tele Caller"
                                            onChange={(event) => setSelectedAssignUser(event.value)}
                                            inputrole='assign user'
                                        />
                                    }
                                />
                            : null}
                        </Grid>
                        <Button type="submit" variant="contained" disableElevation>
                            Assign Lead
                        </Button>
                    </Box>
                </Box>
            </form>

            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    );
};

export default AssignLeadForm;