import React from "react";
import Button from "@mui/material/Button";
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from 'dayjs';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { CustomInputField } from "../../../Components/Common";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import Datatable from "../../../Components/Datatable/Datatable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllProjectNameData, setAddProjectName, setDeleteProjectName, getEditProjectName, setUpdateProjectName, setImportProjectName } from "./ApiCalls";
// import { useNavigate } from "react-router-dom";

const ProjectName = () => {

    // const navigate = useNavigate();
    const [projectnamedata, setAllProjectNameData] = React.useState([]);
    const [editprojectname, setEditProjectName] = React.useState();
    const [editbuildername, setEditBuilderName] = React.useState();
    const [editprj_id, setEditProjectNameID] = React.useState();

    const notifyAddSuccess = () => toast.success("Project Name Added Succefully");

    const notifyDeleteSuccess = () => toast.success("Project Name Deleted Succefully");

    const notifyUpdateSuccess = () => toast.success("Project Name Updated Succefully");

    // const notifyImportSuccess = () => toast.success("Project Name CSV Import Succefully");

    const notifyAddFailed = () => toast.error("Failed! Project Name with same name already exist");

    const notifyDeleteFailed = () => toast.error("Failed! Not Deleted");

    const notifyUpdateFailed = () => toast.error("Failed! Project Name with same name already exist");

    // const notifyImportFailed = () => toast.error("Failed! Project Name with same name already exist");

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        getAllProjectNameData().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllProjectNameData(response.data.data)
            } else {
                setAllProjectNameData(response.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const handleDeleteProjectName = (prj_id) => (event) => {
        event.preventDefault();
        if (window.confirm('Are Your Sure! Delete the Project Name?')) {
            // handleOpen();
            const data = prj_id;
            setDeleteProjectName(data)
                .then((response) => {
                    console.log(response);
                    // navigate("/projectname");
                    if (response.data === "Project Name deleted") {
                        // handleClose();
                        getAllProjectNameData().then(response => {
                            console.log(response);
                            if (response.data === "No Data Found") {
                                setAllProjectNameData(response.data.data)
                            } else {
                                setAllProjectNameData(response.data)
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                        notifyDeleteSuccess();
                    } else {
                        notifyDeleteFailed();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleUpdateProjectName = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("project_name"));
        // console.log(data.get("prj_id"));
        setUpdateProjectName(data)
            .then((response) => {
                console.log(response);
                // navigate("/projectname");
                if (response.data === "Project Name Updated") {
                    handleClose();
                    getAllProjectNameData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllProjectNameData(response.data.data)
                        } else {
                            setAllProjectNameData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    notifyUpdateSuccess();
                } else {
                    notifyUpdateFailed();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleAddProjectName = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("project_name"));
        // alert(data.get("builder_name"));
        // console.log(data.get("project_name"));
        setAddProjectName(data)
            .then((response) => {
                console.log(response);
                // navigate("/projectname");
                if (response.data === "Project Name Added") {
                    handleClose();
                    getAllProjectNameData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllProjectNameData(response.data.data)
                        } else {
                            setAllProjectNameData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    notifyAddSuccess();
                } else {
                    notifyAddFailed();
                }
            })
        .catch((err) => {
            console.log(err);
        });
    };

    const handImportProjectName = (event) => {
        event.preventDefault();
        // alert('Improt Work');
        // const data = new FormData(event.currentTarget);
        // alert(data.get("projectname"));
        // console.log(data);
        setImportProjectName()
            .then((response) => {
                console.log(response);
                // navigate("/projectname");
                // if (response.data === "Project Name Added") {
                    // handleClose();
                    // getAllProjectNameData().then(response => {
                    //     console.log(response);
                    //     if (response.data === "No Data Found") {
                    //         setAllProjectNameData(response.data.data)
                    //     } else {
                    //         setAllProjectNameData(response.data)
                    //     }
                    // }).catch(err => {
                    //     console.log(err);
                    // });
                //     notifyImportSuccess();
                // } else {
                //     notifyImportFailed();
                // }
            })
        .catch((err) => {
            console.log(err);
        });
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "Editbtn":
                return {
                    ProjectNameLable : "Project Name",
                    ModalTitle: "Edit Project Name",
                    SubmitHandle: handleUpdateProjectName,
                    ProjectNameValue: editprojectname,
                    BuilderNameValue: editbuildername,
                    BtnName: "Update Project Name",
                }
            case "Addbtn":
                return {
                    ProjectNameLable : "Project Name",
                    ModalTitle: "Add Project Name",
                    SubmitHandle: handleAddProjectName,
                    ProjectNameValue: "",
                    BuilderNameValue: "",
                    BtnName: "Add Project Name",
                }
            case "OnProjectNameChange": {
                const newValue = action.payload;
                return {
                    ProjectNameLable : state.ProjectNameLable,
                    ModalTitle: state.ModalTitle,
                    SubmitHandle: state.SubmitHandle,
                    ProjectNameValue: newValue,
                    BuilderNameValue:state.BuilderNameValue,
                    BtnName: state.BtnName,
                };
            }
            case "OnBuilderNameChange": {
                const newValue = action.payload;
                return {
                    ProjectNameLable : state.ProjectNameLable,
                    ModalTitle: state.ModalTitle,
                    SubmitHandle: state.SubmitHandle,
                    ProjectNameValue: state.ProjectNameValue,
                    BuilderNameValue: newValue,
                    BtnName: state.BtnName,
                };
            }
            case "Importbtn": {
                return {
                    ProjectNameLable : "Upload CSV",
                    ModalTitle: "Import Project Name",
                    SubmitHandle: handImportProjectName,
                    ProjectNameValue: "",
                    BtnName: "Import Project Name",
                };
            }
            default:
                return state;
        }
    }

    const [state, dispatch] = React.useReducer(reducer,
        { ProjectNameLable: "Project Name", ModalTitle: "Add Project Name", SubmitHandle: "", ProjectNameValue: "", BuilderNameValue: "", BtnName: "Add Project Name" })


    const handleEditProjectName = (prj_id) => (event) => {
        event.preventDefault();
        const data = prj_id;
        // alert(data);
        getEditProjectName(data).then(response => {
            // console.log(response.data[0].project_name);
            if (response.data === "No Project Name Found") {
                // setEditProjectName(response.data.data)
            } else {
                dispatch({ type: "Editbtn" });
                setEditProjectName(response.data[0].pname);
                setEditBuilderName(response.data[0].bname);
                setEditProjectNameID(response.data[0].prj_id);
                handleOpen();
            }
        }).catch(err => {
            console.log(err);
        });
    };

    const columns = [
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Box sx={{ display: "flex" }}>
                            {/* <Tooltip
                                title="Edit"
                                component="label"
                                onClick={
                                    handleEditProjectName(projectnamedata[dataIndex].prj_id)
                                }>
                                <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Delete"
                                component="label"
                                onClick={
                                    handleDeleteProjectName(projectnamedata[dataIndex].prj_id)
                                }>
                                <IconButton><DeleteIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip> */}
                            <Tooltip
                                title="Not allowed"
                                component="label"
                            >
                                <IconButton><NotInterestedIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
        {
            name: "prj_id",
            label: "Project Name Id",
        },
        {
            name: "create_dt",
            label: "Posted On",
        },
        {
            name: "update_dt",
            label: "Updated On",
        },
        {
            name: "project_name",
            label: "Project Name",
        },
        {
            name: "builder_name",
            label: "Builder Name",
        },
    ];

    const ModalOpenAdd = () => {
        dispatch({ type: "Addbtn" });
        handleOpen();
    }
    const ModalOpenImport = () => {
        dispatch({ type: "Importbtn" });
        handleOpen();
    }

    const handleChange = (rdata) => {
        console.log(rdata)
    }

    return (
        <>
            <ToastContainer />
            <Breadcrumb PageName="Project Name" />
            <Box sx={{ mb: 2}} className='cst-btn'>
                <CustomModal
                    BtnName="Add Project Name"
                    ImportBtnName = "Import Project Name"
                    OpenAddModalfun={ModalOpenAdd}
                    OpenImportModalfun={ModalOpenImport}
                    handeleclosefun={handleClose}
                    ModalTitle={state.ModalTitle}
                    open={open}
                    ModalContent={
                        <>
                            <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "600", letterSpacing: ".5px" }} mb={1} >{state.ProjectNameLable}</Typography>
                            <Box component="form" autoComplete="off"
                                onSubmit={state.SubmitHandle} method="post">
                                {state.ModalTitle === "Edit Project Name" ?
                                    <CustomInputField
                                        type="hidden"
                                        name="prj_id"
                                        value={editprj_id}
                                        placeholder="Enter Project Name Id"
                                        required="required"
                                    />
                                    : null}
                                {state.ModalTitle === "Add Project Name" || state.ModalTitle === "Edit Project Name" ?    
                               <>
                               <CustomInputField
                                    type="text"
                                    name="project_name"
                                    value={state.ProjectNameValue}
                                    onchange={event =>
                                        dispatch({ type: "OnProjectNameChange", payload: event.target.value })}
                                    placeholder="Enter Project Name"
                                    required="required"
                                />
                                
                                <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "600", letterSpacing: ".5px" }} mb={1} mt={1} >Builder Name</Typography>
                                <CustomInputField
                                    type="text"
                                    name="builder_name"
                                    value={state.BuilderNameValue}
                                    onchange={event =>
                                        dispatch({ type: "OnBuilderNameChange", payload: event.target.value })}
                                    placeholder="Enter Builder Name"
                                    required="required"
                                />
                                </>
                                
                                : null}
                                {state.ModalTitle === "Import Project Name" ?
                                    <CustomInputField
                                        type="file"
                                        name="project_name_csv"
                                        required="required"
                                        accept=".csv"
                                    />
                                    : null}
                                <Button variant="contained" type="submit" startIcon={<AddIcon />} sx={{ mt: 2 }}>
                                    {state.BtnName}
                                </Button>
                            </Box>
                        </>
                    }
                />
            </Box>

            <Datatable sendDataToParent = {handleChange} TabelTitle="Add Project Name" data={projectnamedata?.map(src => {
                return [
                    src,
                    src.prj_id,
                    // dayjs(src.create_dt).format('DD-MM-YYYY HH:mm:ss'),
                    dayjs().format('YYYY-MM-DD') === dayjs(src.create_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(src.create_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(src.create_dt).format('hh:MM a'),
                    dayjs().format('YYYY-MM-DD') === dayjs(src.update_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(src.update_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(src.update_dt).format('hh:MM a'),
                    src.pname,
                    src.bname,
                ]
            })} columns={columns} />

        </>
    );
};
export default ProjectName;
