import React, {useState, useEffect} from 'react';
import {
    Box,
    Typography,
    Grid
} from "@mui/material";
import "./ViewLead.css";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";
import { getLeadData, getLeadAssignUserList } from './ApiCalls';
import dayjs from 'dayjs';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { getLeadAssignUserData } from '../EditLead/ApiCalls';

// 
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ViewLead = () => {

    const [ClientPersonalDetails, setClientPersonalDetails] = useState([]);
    const [ClientStatus, setClientStatus] = useState([]);
    const [ClientRequirementDetails, setClientRequirementDetails] = useState([]);
    const [leadAssignUserList, setLeadAssignUserList] = React.useState([]);

    let lead_id = useParams() 

    const [myTabs, setMyTabs] = React.useState('default');
    const handleTabChange = (event, newValue) => {

      if(newValue === 'default'){
          leadDetailData()
        }else{
          getLeadAssignUserData(newValue, lead_id).then((response)=>{
            console.log(response)
            setClientPersonalDetails([{ label: "Posted On", desc: response.data[0].create_dt },{ label: "Lead Type", desc: response.data[0].source_type },{ label: "Source", desc: response.data[0].source },
            { label: "Lead Name", desc: response.data[0].lname},{ label: "Mobile No.", desc: '+'+response.data[0].p_ccode+' '+response.data[0].p_mob},
          //   { label: "Alternate Mobile No.", desc: '+'+response.data[0].s_ccode+' '+response.data[0].s_mob },
            { label: "Email Id.", desc: response.data[0].p_email },
          //   { label: "Alternate Email Id.", desc: response.data[0].s_email }
          ])
    
          setClientStatus([
              { label: "Lead Status", desc: response.data[0].status },
            { label: "Followup Date", desc: dayjs(response.data[0].followup_dt).format('YYYY-MM-DD') },
            { label: "Followup Time", desc:dayjs(response.data[0].followup_dt).format('hh:mm A')},
            { label: "Project Name", desc: response.data[0].pname}]) 
    
          setClientRequirementDetails([{ label: "Service Type", desc: response.data[0].service_type},
            { label: "Property Type", desc: response.data[0].ptype },{ label: "Property Category", desc: response.data[0].pcategory },{ label: "Configuration", desc: response.data[0].pconfiguration},
            { label: "Property Area", desc: response.data[0].min_area+' - '+response.data[0].max_area+' '+response.data[0].area_unit},{ label: "Property Price", desc: response.data[0].min_price+' - '+response.data[0].max_price+' '+(response.data[0].price_unit.split('~')[0]) },{ label: "Country", desc: response.data[0].country },{ label: "State", desc: response.data[0].state},{ label: "City", desc: response.data[0].city },{ label: "Locality", desc: response.data[0].locality },{ label: "Sub Locality", desc: response.data[0].sub_locality}, { label: "Other Details", desc: response.data[0].other_details}])
    
          }).catch((err)=>{
            console.log(err)
          })
        }
        setMyTabs(newValue);
       console.log(newValue)
    }

    useEffect(() => {

        getLeadAssignUserList(lead_id).then(response => {
            setLeadAssignUserList(response.data)
          }).catch(err => {
             console.log(err)
          })
          leadDetailData()
        console.log(lead_id)
        
      }, []);

      const leadDetailData = () => {
        getLeadData(lead_id).then((response)=>{
          console.log(response)
          setClientPersonalDetails([{ label: "Posted On", desc: response.data[0].create_dt },{ label: "Lead Type", desc: response.data[0].source_type },{ label: "Source", desc: response.data[0].source },
          { label: "Lead Name", desc: response.data[0].lname},{ label: "Mobile No.", desc: '+'+response.data[0].p_ccode+' '+response.data[0].p_mob},
        //   { label: "Alternate Mobile No.", desc: '+'+response.data[0].s_ccode+' '+response.data[0].s_mob },
          { label: "Email Id.", desc: response.data[0].p_email },
        //   { label: "Alternate Email Id.", desc: response.data[0].s_email }
        ])

        setClientStatus([
            { label: "Lead Status", desc: response.data[0].status },
          { label: "Followup Date", desc: dayjs(response.data[0].followup_dt).format('YYYY-MM-DD') },
          { label: "Followup Time", desc:dayjs(response.data[0].followup_dt).format('hh:mm A')},
          { label: "Project Name", desc: response.data[0].pname}]) 

        setClientRequirementDetails([{ label: "Service Type", desc: response.data[0].service_type},
          { label: "Property Type", desc: response.data[0].ptype },{ label: "Property Category", desc: response.data[0].pcategory },{ label: "Configuration", desc: response.data[0].pconfiguration},
          { label: "Property Area", desc: response.data[0].min_area+' - '+response.data[0].max_area+' '+response.data[0].area_unit},{ label: "Property Price", desc: response.data[0].min_price+' - '+response.data[0].max_price+' '+(response.data[0].price_unit.split('~')[0]) },{ label: "Country", desc: response.data[0].country },{ label: "State", desc: response.data[0].state},{ label: "City", desc: response.data[0].city },{ label: "Locality", desc: response.data[0].locality },{ label: "Sub Locality", desc: response.data[0].sub_locality}, { label: "Other Details", desc: response.data[0].other_details}])

        }).catch((err)=>{
          console.log(err)
        })
      }

      
    return (
        <>
            <Breadcrumb PageName="View Lead" BackButton={[true, 'Back', '/total-leads/mypage='+lead_id.pageId.split('=')[1], <ArrowBackIosOutlinedIcon />]} />
            <Box className="custom-card">
            {/* {leadAssignUserList !== 'No Data Found' ?   
            <Grid item xs={6} md={6} style={{ padding: '0%'}}>
              <Box sx={{borderBottom: 1, borderColor: 'divider' , paddingTop: '0.1rem'}}>
                <Tabs 
                  value={myTabs} 
                  onChange={handleTabChange} 
                  aria-label="scrollable auto tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label={'Default(Master/Admin)'} {...a11yProps(0)} value={'default'} />
                  {leadAssignUserList.map((users)=><Tab label={users.username+'('+users.urole+')'} {...a11yProps(0)} value={users.assignto_id} />)}
                </Tabs>
              </Box>
            </Grid> : ''}
            {leadAssignUserList !== 'No Data Found' ?
            <Box p={2} >
             <Grid container spacing={2} mb={2} style={{paddingTop: '0%'}}>
              <Grid item md={12}>
                <Alert severity="info" sx={{borderRadius: '10px'}}>
                  <AlertTitle>Info.,</AlertTitle>
                  Client Requirement Data is - <strong>Loading!</strong>
                </Alert>
              </Grid> 
            </Grid>
           </Box> 
           : ''  } */}

                <Typography variant="h4" className="custom-card-head">
                    View Lead Details :-
                </Typography>
                <Box p={2}>
                    <Grid container spacing={2} mb={2}>
                        {ClientPersonalDetails.map((details, id) => {
                            return (
                                details.desc !== "" && details.desc !== "null" ?
                                    <Grid item xs={12} md={6} key={id}>
                                        <Box className="details-card">
                                            <Typography variant='span' className='custom-subhead' sx={{ color: "#000", fontWeight: "500", width: "30%" }}>{details.label}</Typography>
                                            <div sx={{ width: "5%" }}>-</div>
                                            <Typography variant='span' className='custom-subhead' sx={{ width: "65%", textAlign: "left" }}>{details.desc}</Typography>
                                        </Box>
                                    </Grid>
                                : null
                            );
                        })}
                    </Grid>
                </Box>
            </Box>
            <Box className="custom-card" mt={2}>
                <Typography variant="h4" className="custom-card-head">
                    Client Status :-
                </Typography>
                <Box p={2}>
                    <Grid container spacing={2} mb={2}>
                        {ClientStatus.map((details, id) => {
                            return (
                                details.desc !== "" && details.desc !== "null" ?
                                    <Grid item xs={12} md={6} key={id}>
                                        <Box className="details-card">
                                            <Typography variant='span' className='custom-subhead' sx={{ color: "#000", fontWeight: "500", width: "30%" }}>{details.label}</Typography>
                                            <div sx={{ width: "5%" }}>-</div>
                                            <Typography variant='span' className='custom-subhead' sx={{ width: "65%", textAlign: "left" }}>{details.desc}</Typography>
                                        </Box>
                                    </Grid>
                                : null
                            );
                        })}
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                        <Box className="details-card">
                            <Typography variant='span' className='custom-subhead' sx={{ color: "#000", fontWeight: "500", width: "15%" }}>Comments</Typography>
                            <div sx={{ width: "5%" }}>-</div>
                            <Typography variant='span' className='custom-subhead' sx={{ width: "85%", textAlign: "left", ml: "15px" }}>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error, rerum cupiditate itaque vitae quae earum labore molestiae, rem molestias ducimus quisquam laborum sint doloribus illo. Laudantium saepe repellat ipsum fugit.
                            </Typography>
                        </Box>
                    </Grid> */}
                </Box>
            </Box>
            <Box className="custom-card" mt={2}>
                <Typography variant="h4" className="custom-card-head">
                    Client Requirement Details :-
                </Typography>
                <Box p={2}>
                    <Grid container spacing={2} mb={2}>
                        {ClientRequirementDetails.map((details, id) => {
                            return (
                                details.desc !== "" && details.desc !== "null"?
                                    <Grid item xs={12} md={6} key={id}>
                                        <Box className="details-card">
                                            <Typography variant='span' className='custom-subhead' sx={{ color: "#000", fontWeight: "500", width: "30%" }}>{details.label}</Typography>
                                            <div sx={{ width: "5%" }}>-</div>
                                            <Typography variant='span' className='custom-subhead' sx={{ width: "65%", textAlign: "left" }}>{details.desc}</Typography>
                                        </Box>
                                    </Grid>
                                : null
                            );
                        })}
                        {/* <Grid item xs={12} md={12}>
                            <Box className="details-card">
                                <Typography variant='span' className='custom-subhead' sx={{ color: "#000", fontWeight: "500", width: "15%" }}>Other Details</Typography>
                                <div sx={{ width: "5%" }}>-</div>
                                <Typography variant='span' className='custom-subhead' sx={{ width: "85%", textAlign: "left", ml: "15px" }}>
                                     
                                </Typography>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default ViewLead

