import React from "react";
import Button from "@mui/material/Button";
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from 'dayjs';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { CustomInputField, CustomSelectFiled, CustomFormGroup } from "../../../Components/Common";
import { PropertyType } from '../../../Data/Data';
import CustomModal from "../../../Components/CustomModal/CustomModal";
import Datatable from "../../../Components/Datatable/Datatable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllConfigurationData, setAddConfiguration, setDeleteConfiguration, getEditConfiguration, setUpdateConfiguration, setImportConfiguration } from "./ApiCalls";
// import { useNavigate } from "react-router-dom";

const Configuration = () => {

    // const navigate = useNavigate();
    const [configurationdata, setAllConfigurationData] = React.useState([]);
    const [ptype, setPtype] = React.useState();
    const [editconfiguration, setEditConfiguration] = React.useState();
    const [editconfi_id, setEditConfigurationID] = React.useState();

    const notifyAddSuccess = () => toast.success("Configuration Added Succefully");

    const notifyDeleteSuccess = () => toast.success("Configuration Deleted Succefully");

    const notifyUpdateSuccess = () => toast.success("Configuration Updated Succefully");

    // const notifyImportSuccess = () => toast.success("Configuration CSV Import Succefully");

    const notifyAddFailed = () => toast.error("Failed! Configuration with same name already exist");

    const notifyDeleteFailed = () => toast.error("Failed! Not Deleted");

    const notifyUpdateFailed = () => toast.error("Failed! Configuration with same name already exist");

    // const notifyImportFailed = () => toast.error("Failed! Configuration with same name already exist");

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        getAllConfigurationData().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllConfigurationData(response.data.data)
            } else {
                setAllConfigurationData(response.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const handleDeleteConfiguration = (confi_id) => (event) => {
        event.preventDefault();
        if (window.confirm('Are Your Sure! Delete the Configuration?')) {
            // handleOpen();
            const data = confi_id;
            setDeleteConfiguration(data)
                .then((response) => {
                    console.log(response);
                    // navigate("/configuration");
                    if (response.data === "Configuration deleted") {
                        // handleClose();
                        getAllConfigurationData().then(response => {
                            console.log(response);
                            if (response.data === "No Data Found") {
                                setAllConfigurationData(response.data.data)
                            } else {
                                setAllConfigurationData(response.data)
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                        notifyDeleteSuccess();
                    } else {
                        notifyDeleteFailed();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleUpdateConfiguration = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("confi_id"));
        // alert(data.get("configuration_type"));
        // alert(data.get("configuration"));
        // console.log(data.get("confi_id"));
        setUpdateConfiguration(data)
            .then((response) => {
                console.log(response);
                // navigate("/configuration");
                if (response.data === "Configuration Updated") {
                    handleClose();
                    getAllConfigurationData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllConfigurationData(response.data.data)
                        } else {
                            setAllConfigurationData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    notifyUpdateSuccess();
                } else {
                    notifyUpdateFailed();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleAddConfiguration = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("configuration_type"));
        // alert(data.get("configuration"));
        setAddConfiguration(data)
            .then((response) => {
                console.log(response);
                // navigate("/configuration");
                if (response.data === "Configuration Added") {
                    handleClose();
                    getAllConfigurationData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllConfigurationData(response.data.data)
                        } else {
                            setAllConfigurationData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    notifyAddSuccess();
                } else {
                    notifyAddFailed();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handImportConfiguration = (event) => {
        event.preventDefault();
        // alert('Improt Work');
        // const data = new FormData(event.currentTarget);
        // alert(data.get("configuration"));
        // console.log(data);
        setImportConfiguration()
            .then((response) => {
                console.log(response);
                // navigate("/configuration");
                // if (response.data === "Configuration Added") {
                // handleClose();
                // getAllConfigurationData().then(response => {
                //     console.log(response);
                //     if (response.data === "No Data Found") {
                //         setAllConfigurationData(response.data.data)
                //     } else {
                //         setAllConfigurationData(response.data)
                //     }
                // }).catch(err => {
                //     console.log(err);
                // });
                //     notifyImportSuccess();
                // } else {
                //     notifyImportFailed();
                // }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "Editbtn":
                return {
                    ConfigurationLable: "Configuration",
                    ModalTitle: "Edit Configuration",
                    SubmitHandle: handleUpdateConfiguration,
                    ConfigurationValue: editconfiguration,
                    ConfigurationTypeValue: ptype,
                    BtnName: "Update Configuration",
                }
            case "Addbtn":
                return {
                    ConfigurationLable: "Configuration",
                    ModalTitle: "Add Configuration",
                    SubmitHandle: handleAddConfiguration,
                    ConfigurationValue: "",
                    ConfigurationTypeValue: null,
                    BtnName: "Add Configuration",
                }
            case "OnConfigurationChange": {
                const newValue = action.payload;
                return {
                    ConfigurationLable: state.ConfigurationLable,
                    ModalTitle: state.ModalTitle,
                    SubmitHandle: state.SubmitHandle,
                    ConfigurationValue: newValue,
                    ConfigurationTypeValue: state.ConfigurationTypeValue,
                    BtnName: state.BtnName,
                };
            }
            case "Importbtn": {
                return {
                    ConfigurationLable: "Upload CSV",
                    ModalTitle: "Import Configuration",
                    SubmitHandle: handImportConfiguration,
                    ConfigurationValue: "",
                    ConfigurationTypeValue: "",
                    BtnName: "Import Configuration",
                };
            }
            default:
                return state;
        }
    }

    const [state, dispatch] = React.useReducer(reducer,
        { ConfigurationLable: "Configuration", ModalTitle: "Add Configuration", SubmitHandle: "", ConfigurationValue: "", ConfigurationTypeValue: "", BtnName: "Add Configuration" })

    const handleEditConfiguration = (confi_id) => (event) => {
        event.preventDefault();
        const data = confi_id;
        getEditConfiguration(data).then(response => {
            console.log(response.data[0].configuration_type);
            if (response.data === "No Configuration Found") {
                // setEditConfiguration(response.data.data)
            } else {
                dispatch({ type: "Editbtn" });
                setPtype(response.data[0].configuration_type);
                setEditConfiguration(response.data[0].configuration);
                setEditConfigurationID(response.data[0].confi_id);
                handleOpen();
            }
        }).catch(err => {
            console.log(err);
        });
    };

    const columns = [
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Box sx={{ display: "flex" }}>
                            {/* <Tooltip
                                title="Edit"
                                component="label"
                                onClick={
                                    handleEditConfiguration(configurationdata[dataIndex].confi_id)
                                }>
                                <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Delete"
                                component="label"
                                onClick={
                                    handleDeleteConfiguration(configurationdata[dataIndex].confi_id)
                                }>
                                <IconButton><DeleteIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip> */}
                            <Tooltip
                                title="Not allowed"
                                component="label"
                            >
                                <IconButton><NotInterestedIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
        {
            name: "confi_id",
            label: "Configuration Id",
        },
        {
            name: "create_dt",
            label: "Posted On",
        },
        {
            name: "update_dt",
            label: "Updated On",
        },
        {
            name: "configuration_type",
            label: "Type",
        },
        {
            name: "configuration",
            label: "Configuration",
        },
    ];

    const ModalOpenAdd = () => {
        dispatch({ type: "Addbtn" });
        handleOpen();
    }
    const ModalOpenImport = () => {
        dispatch({ type: "Importbtn" });
        handleOpen();
    }

    const handleChange = (rdata) => {
        console.log(rdata)
    }

    return (
        <>
            <ToastContainer />
            <Breadcrumb PageName="Configuration" />
            <Box sx={{ mb: 2 }} className='cst-btn'>
                <CustomModal
                    BtnName="Add Configuration"
                    ImportBtnName="Import Configuration"
                    OpenAddModalfun={ModalOpenAdd}
                    OpenImportModalfun={ModalOpenImport}
                    handeleclosefun={handleClose}
                    ModalTitle={state.ModalTitle}
                    open={open}
                    ModalContent={
                        <>
                            <Box component="form" autoComplete="off"
                                onSubmit={state.SubmitHandle} method="post">
                                {state.ModalTitle === "Edit Configuration" ?
                                    <CustomInputField
                                        type="hidden"
                                        name="confi_id"
                                        value={editconfi_id}
                                        placeholder="Enter Configuration Id"
                                        required="required"
                                    />
                                    : null}
                                   {state.ModalTitle === "Add Configuration" || state.ModalTitle === "Edit Configuration" ?
                                    <>  
                                        <CustomFormGroup
                                            formlabel="Property Type"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="configuration_type"
                                                    required="required"
                                                    defaultValue = {
                                                        state.ModalTitle === "Add Configuration" ? 
                                                        state.ConfigurationTypeValue
                                                        : 
                                                        {label: state.ConfigurationTypeValue, value: state.ConfigurationTypeValue}
                                                    }
                                                    options={PropertyType}
                                                    onChange={(event) => setPtype(event.value)}
                                                />
                                            }
                                        />
                                        <CustomFormGroup 
                                            mtprop="1rem"
                                            formlabel="Configuration"
                                            FormField={
                                                <CustomInputField
                                                    type="text"
                                                    name="configuration"
                                                    value={state.ConfigurationValue}
                                                    onchange={event =>
                                                        dispatch({ type: "OnConfigurationChange", payload: event.target.value })}
                                                    placeholder="Enter Configuration"
                                                    required="required"
                                                />
                                            }
                                        />
                                    </>
                                    : null}
                                   {state.ModalTitle === "Import Configuration" ?
                                    <CustomInputField
                                        type="file"
                                        name="configuration_csv"
                                        required="required"
                                        accept=".csv"
                                    />
                                    : null}
                                <Button variant="contained" type="submit" startIcon={<AddIcon />} sx={{ mt: 2 }}>
                                    {state.BtnName}
                                </Button>
                            </Box>
                        </>
                    }
                />
            </Box>

            <Datatable sendDataToParent = {handleChange} TabelTitle="Add Configuration" data={configurationdata?.map(confi => {
                return [
                    confi,
                    confi.confi_id,
                    dayjs().format('YYYY-MM-DD') === dayjs(confi.create_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(confi.create_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(confi.create_dt).format('hh:MM a'),
                    dayjs().format('YYYY-MM-DD') === dayjs(confi.update_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(confi.update_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(confi.update_dt).format('hh:MM a'),
                    confi.configuration_type,
                    confi.configuration,
                ]
            })} columns={columns} />

        </>
    );
};
export default Configuration;
