import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import FlareIcon from '@mui/icons-material/Flare';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

function createlData(bgcolor, title, desc) {
    return {
      bgcolor,
      title,
      desc,
    };
}
const crmdetails = [
  createlData("green",'New Leads', 50),
  createlData("red",'Not Interested', 5),
  createlData("blue",'Ringing', 5),
  createlData("grey",'Disconnected', 10),
  createlData("pink",'Call Back Later', 5),
  createlData("#e4e4e4",'Busy On Another Call', 10),
  createlData("#333",'Wrong Number', 7),
  createlData("purple",'Broker Numbers', 8)
];

const LeadStatus = () => {
  return (
    <Box className="cstbox" > 
        <h5 className="ltitle" style={{marginBottom: '10px'}}>Leads Status</h5>
        <List sx={{height: '360px', overflowY: 'scroll'}}>
            {crmdetails.map((row, index) => {
                return (
                  <ListItem key={index}>
                      <ListItemAvatar>
                          <Box
                            sx={{
                              width: '35px',
                              height: '35px',
                              borderRadius: '2px',
                              opacity: '.8',
                              boxShadow: `${'0 0 2px 0 '+row.bgcolor}`,
                              backgroundColor: `${row.bgcolor}`,
                              '&:hover': {
                                backgroundColor: 'primary.main',
                                opacity: [0.9, 0.8, 0.7],
                              },
                            }}
                          />    
                      </ListItemAvatar>
                      <ListItemText primary={row.title} secondary={'Total: '+row.desc} />
                  </ListItem>
                )
            })}
        </List>
    </Box>
  )
}

export default LeadStatus;