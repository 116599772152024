import React from "react";
import { styled, alpha } from "@mui/material/styles";
import {
    Card,
    Grid,
    CardActions,
    CardContent,
    Typography,
    IconButton,
    Tooltip,
    InputBase,
    Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import "./AllStatus.css";
import { Link } from "react-router-dom";
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SourceCountCard from '../../Components/SourceCountCard/SourceCountCard';
import { useQuery } from "react-query";
import { getStatus, getLeadCount } from "./ApiCalls";
import Alert from "@mui/material/Alert";

function createsData(avatar, uname, uid, role, dated) {
    return {
      avatar,
      uname,
      uid,
      role,
      dated,
    };
}

const AllStatus = () => {

    const allstatus = useQuery("allStatus", () => {
        return getStatus()
      },{})
      
    const undefinednewleadcount = useQuery("allundefinednew", () => {
        return getLeadCount()
      },{})
      if(undefinednewleadcount.isLoading || allstatus.isLoading){
        return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
      }
      if(undefinednewleadcount.isError || allstatus.isError){
        return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
      }

      console.log(undefinednewleadcount)

    const usercard = [  
        createsData("M", "Admin", "132", "Master", "2023 / 01 / 30 12:26"),
    ]; 

    return (
        <>
            <Breadcrumb PageName="All Users" />
            <Grid container pt={2.5} spacing={4}>
                <Grid item xs={12} md={3} component={Link} to={`/leads-by-status/${'new_leads'}/`}>
                            <SourceCountCard
                                SourceImg={'NL'}
                                SourceName={'New Leads'}
                                LeadCount={undefinednewleadcount.data.newleadcount}
                                cardRole='usercard'
                            />
                </Grid>
                <Grid item xs={12} md={3} component={Link} to={`/leads-by-status/${'undefined'}/`}>
                            <SourceCountCard
                                SourceImg={'UL'}
                                SourceName={'Undefined Leads'}
                                LeadCount={undefinednewleadcount.data.undefinedcount}
                                cardRole='usercard'
                            />
                </Grid>
            
                {allstatus.data.data.map((row, index) => {
                    return (
                        <Grid item xs={12} md={3} key={index} component={Link} to={`/leads-by-status/${row.status}/`}>
                            <SourceCountCard
                                SourceImg={row.status.charAt(0)+'L'}
                                SourceName={row.status}
                                LeadCount={row.status_count}
                                cardRole='usercard'
                            />
                        </Grid>
                    )
                })}

            </Grid>
        </>
    );
};

export default AllStatus;
