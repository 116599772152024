import Axios from 'axios';
import dayjs from 'dayjs';
import { API_URL } from '../../../DesktopApp';

const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getUsers = async() => {
  try{
    const response = await Axios.post(`${API_URL}/users/getUserList`);
    return response;
  }catch(err){
    return err;
  }
};

const setUser = async(data) =>{
  try{
    const response = await Axios.post(`${API_URL}/users/createUser`,{
      create_dt: date,
      update_dt: date,
      username: data.get('username'),
      password: data.get('password'),
      utype: data.get('user_type'),
      urole: data.get('user_role'),
      team_leader: data.get('team_leader'),
      sales_manager: data.get('sales_manager'),
    });
    return response;
  }catch(err){
    return err;
  }
}

const getCrmDetails = async() => {
  try{
    const response = await Axios.post(`${API_URL}/desktop/crmdetails/getCrmDetails`);
    return response;
  }catch(err){
    return err;
  }
};

export {getUsers, setUser, getCrmDetails};