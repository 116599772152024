import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const getLeads = async(status) => {
    try{
      if(status === 'new_leads'){
        const response = await Axios.post(`${API_URL}/status/getNewLeads`, {limit: 10, page: 0, status: status});
        const result = (response.data !== 'No Data Found' ? response.data.map((leads)=>(
          {
            id: leads.l_id,
            name: leads.lname,
            mobile: leads.p_mob,
            email: leads.p_email,
            pname: leads.pname,
            lead_status: leads.status,
            lead_date: leads.create_dt,
            source: leads.source,
            city: leads.city,
            locality: leads.locality,
            assigned_telecaller: "-",
            assigned_sales_manager: "-",
            assigned_team_leader: "Testing",
            assigned_branch_admin: "Tester",
            posted_by: "Master",
          }
          )) : 'No Data Found');
          return result

      }else if(status === 'undefined'){
        const response = await Axios.post(`${API_URL}/status/getUndefinedLeads`, {limit: 10, page: 0, status: status});
        const result = (response.data !== 'No Data Found' ? response.data.map((leads)=>(
          {
            id: leads.l_id,
            name: leads.lname,
            mobile: leads.p_mob,
            email: leads.p_email,
            pname: leads.pname,
            lead_status: leads.status,
            lead_date: leads.create_dt,
            source: leads.source,
            city: leads.city,
            locality: leads.locality,
            assigned_telecaller: "-",
            assigned_sales_manager: "-",
            assigned_team_leader: "Testing",
            assigned_branch_admin: "Tester",
            posted_by: "Master",
          }
          )) : 'No Data Found');
          return result
      }else{
        const response = await Axios.post(`${API_URL}/status/getStatusWiseLeads`, {limit: 10, page: 0, status: status});
        const result = (response.data !== 'No Data Found' ? response.data.map((leads)=>(
          {
            id: leads.l_id,
            name: leads.lname,
            mobile: leads.p_mob,
            email: leads.p_email,
            pname: leads.pname,
            lead_status: leads.status,
            lead_date: leads.create_dt,
            source: leads.source,
            city: leads.city,
            locality: leads.locality,
            assigned_telecaller: "-",
            assigned_sales_manager: "-",
            assigned_team_leader: "Testing",
            assigned_branch_admin: "Tester",
            posted_by: "Master",
          }
          )) : 'No Data Found');
          return result
      }
    }catch(err){
        return err;
    }
}

const unSetLead = async(lead_id) => {
  try{
    const response = await Axios.post(`${API_URL}/leads/deletelead`, {
      lid: lead_id,
    });
    return response;
  }catch(err){
      return err;
  }
};

export {getLeads, unSetLead}