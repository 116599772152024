import * as React from "react";
// import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent"; 
import Divider from "@mui/material/Divider";
import "./SourceCountCard.css";
import Fade from '@mui/material/Fade';

export default function SourceCountCard(props) {
  return (
    <Card className="source-card" >
      <Fade in direction='up' timeout={800}>
        <CardContent style={{paddingBottom: "16px"}}>
          {props.cardRole === 'usercard' ? 
            <button className="sravatar">{props.SourceImg}</button>
          : <img src={props.SourceImg} alt={props.SourceName} className="srcimg" />
          }
          <span className="srcname" style={{float: 'right'}}>{props.SourceName}</span>
          <h4 className="srccount">{props.LeadCount}</h4>
          {props.cardRole === 'usercard' ? 
          <>
            <Divider className="bxdivider" />
            <div className="srcpercent">
              <span style={{color: 'green', fontWeight: '500'}}>{props.Percent}</span>
              <div style={{width: '100%', textAlign: 'right'}}>{props.Description}</div>
            </div>
          </> : null}
        </CardContent>
      </Fade>
    </Card>
  );
}