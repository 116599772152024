import React, { Suspense } from 'react';
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Pages/Login';
import Error from './Pages/Error';
import { styled } from "@mui/material/styles";

// desktop
import LeftSideNav from './Desktop/Components/LeftSideNav/LeftSideNav';
import Dashboard from './Desktop/Pages/Dashboard';
import TotalLeads from './Desktop/Pages/TotalLeads/TotalLeads';
import AddLead from './Desktop/Pages/AddLead/AddLead';
import EditLead from './Desktop/Pages/EditLead/EditLead';
import QuickEdit from './Desktop/Pages/QuickEdit/QuickEdit';
import ViewLead from './Desktop/Pages/ViewLead/ViewLead';
import LeadsByStatus from './Desktop/Pages/LeadsByStatus/LeadsByStatus';
import AssignLeadForm from './Desktop/Pages/AssignLeadForm/AssignLeadForm';
import AllUsers from './Desktop/Pages/AllUsers/AllUsers';
import AddUser from './Desktop/Pages/AddUser/AddUser';
import Geolocation from './Desktop/Pages/DynamicFields/Geolocation/Geolocation';
import ProjectName from './Desktop/Pages/DynamicFields/ProjectName/ProjectName';
import Source from './Desktop/Pages/DynamicFields/Source/Source';
import Configuration from './Desktop/Pages/DynamicFields/Configuration/Configuration';
import LeadStatus from './Desktop/Pages/DynamicFields/LeadStatus/LeadStatus';
import AllStatus from './Desktop/Pages/Status/AllStatus';
import ChangePassword from './Desktop/Pages/ChangePassword/ChangePassword';
// ends
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import axios from 'axios';

// pre-catching
import { QueryClientProvider, QueryClient } from "react-query";
import './Desktop/Pages/Common.css';
import BasicTable from './Desktop/Pages/pagination';
import MissedLeads from './Desktop/Pages/MissedLeads/Missedleads';
import TodaysLeads from './Desktop/Pages/TodaysLeads/Todaysleads';
import AssignLeads from './Desktop/Pages/AssignLeads/AssignLeads';
import NonAssignLeads from './Desktop/Pages/NonAssignLeads/NonAssignLeads';

// server api
//export const API_URL = "http://localhost:3004";
//export const API_URL = "https://node.rabs.support";
//export const API_URL = "https://backend.newcrm.propertycloud.in"
export const API_URL = "https://aadeerealtybackend.rabs.support"
axios.defaults.withCredentials = true;

// pre-catching
export const queryClient = new QueryClient();
 
function DesktopApp() { 
    return (
        <QueryClientProvider client={queryClient}>
            <Box sx={{ display: 'flex' }}>
                <Router> 
                    {window.location.pathname === "/leads" ? window.location.pathname = "/login" : null}
                    <Suspense fallback={<Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>} >
                        <Routes>
                            <Route path="/" element={<LeftSideNav />} >
                                <Route path="dashboard" element={<Dashboard />} /> 
                                <Route path="error" element={<Error />} />
                                <Route path="total-leads" element={<TotalLeads />} />
                                <Route path="total-leads/:pageId" element={<TotalLeads />} />
                                <Route path="add-lead" element={<AddLead />} />
                                <Route path="edit-lead/:leadId/:pageId" element={<EditLead />} />
                                <Route path="quick-edit/:leadId/:pageId" element={<QuickEdit />} />
                                <Route path="view-lead/:leadId/:pageId" element={<ViewLead />} />
                                <Route path="assign-leads-to/:leadId/:pageId" element={<AssignLeadForm />} />
                                <Route path="leads-by-status/:status/" element={<LeadsByStatus />} />
                                <Route path="all-users" element={<AllUsers />} />
                                <Route path="add-user" element={<AddUser />} />
                                <Route path="geolocation" element={<Geolocation />} />
                                <Route path="project-name" element={<ProjectName />} />
                                <Route path="source" element={<Source />} />
                                <Route path="configuration" element={<Configuration />} />
                                <Route path="lead-status" element={<LeadStatus />} />
                                <Route path="all-status" element={<AllStatus/>}/>
                                <Route path="change-password" element={<ChangePassword />} /> 
                                <Route path="pagination" element={<BasicTable/>}/>
                                <Route path="missedleads" element={<MissedLeads/>}/>
                                <Route path="todaysleads" element={<TodaysLeads/>}/>
                                <Route path="assignleads" element={<AssignLeads/>}/>
                                <Route path="nonassignleads" element={<NonAssignLeads/>}/>
                            </Route>
                            <Route path="login" element={<Login />} />
                            <Route path="*" element={<Navigate component={Link} to="login" />} />
                        </Routes> 
                    </Suspense>  
                </Router>
            </Box>
        </QueryClientProvider>
    );
}

export default DesktopApp;