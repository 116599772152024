import React from "react";
import Grid from "@mui/material/Grid";
import Google from "../../Assets/images/source/google.png";
import Facebook from "../../Assets/images/source/facebook.png";
import Housing from "../../Assets/images/source/housing.png";
import MagicBricks from "../../Assets/images/source/magicbricks.png";
import Acres from "../../Assets/images/source/acres.png";
import Olx from "../../Assets/images/source/olx.png";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import SourceCountCard from "../../Components/SourceCountCard/SourceCountCard";
import Charts from './Charts/Charts';
import Donut from './Donut/Donut';
import ProjectWise from "./ProjectWise/ProjectWise";
import CrmDetail from './CrmDetail/CrmDetail';
import LeadStatus from "./LeadStatus/LeadStatus";
import Activity from "./Activity/Activity";
import Attendance from "./Attendance/Attendance";
import { useQuery } from "react-query";
import { getSourceCount } from "./ApiCalls";
import { Alert } from "@mui/material";

function createsData(simg, sname, scount, spercent, sdesc) {
  return {
    simg,
    sname,
    scount,
    spercent,
    sdesc,
  };
}
 
const DashboardPage = () => {
 
  const data = useQuery('dashboard', ()=>{
    return getSourceCount()
  });
  console.log(data)
  if(data.isLoading){
    return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
  }
  if(data.isError){
    return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
  }

  const lsrccard = [  
    createsData(Facebook, "Facebook", data.data.facebookCount.data[0].leadcount, "+55.2%", "than yesterday"),
    createsData(Google, "Google", data.data.googleCount.data[0].leadcount, "+10%", "than lask week"),
    createsData(Housing, "Housing", data.data.housingCount.data[0].leadcount, "+34.5%", "than last month"),
    createsData(MagicBricks, "MagicBricks", data.data.magicbricksCount.data[0].leadcount, "+20.7%", "Just updated"),
    createsData(Acres, "99 Acres", data.data.acresCount.data[0].leadcount, "+30.7%", "Just updated")
  ]; 
  
  return (
    <>
      <Breadcrumb PageName="Dashboard" PageDesc="Welcome To Admin Dashboard" />
      <Grid container pt={2.5} spacing={4}>
        {lsrccard.map((row, index) => {
          return (
            <Grid item xs={12} md={3} key={index}>
              <SourceCountCard
                SourceImg={row.simg}
                SourceName={row.sname}
                LeadCount={row.scount}
                Percent={row.spercent}
                Description={row.sdesc}
              />
            </Grid>
          )
        })}
      </Grid>
      <Grid item md={12} sx={{mt:4, mb: 4}}></Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Charts />
        </Grid> 
        <Grid item xs={12} md={4}>
          <LeadStatus /> {/* <Donut /> */}
        </Grid>   
      </Grid>
      {/* <Grid item md={12} sx={{mt:4, mb: 4}}></Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <ProjectWise />
        </Grid> 
        <Grid item xs={12} md={4}>
          <LeadStatus />
        </Grid>   
      </Grid> */}
      <Grid item md={12} sx={{mt:4, mb: 4}}></Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Activity />
        </Grid> 
        <Grid item xs={12} md={4}>
           <Attendance />
        </Grid>
        <Grid item xs={12} md={4}>
          <CrmDetail />
        </Grid>   
      </Grid>
    </>
  );
};

export default DashboardPage;