import React, { useState , useEffect } from "react"; 
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import { Box } from "@mui/system";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Country, State, City } from 'country-state-city'; 
import CurrencyInput from 'react-currency-input-field'; 
import { ToWords } from 'to-words';
import { CurrencyListLocales } from './CurrencyLists';
// import { encode, decode } from 'html-entities';
import "./Common.css";

const isLetters = (str) => /^[A-Za-z ]*$/.test(str);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const InputErrorMessage = (props) => {
  return (
    <Alert
      variant="outlined"
      severity="error" 
      className="common-error" 
    >
      {props.errormsg}
    </Alert>
  );
}

export const InputsLabel = (props) => {
  return (
    <div
      style={{ 
        fontSize: ".90rem",
        fontWeight: "500",
        width: '100%',
        textTransform: 'capitalize'
      }}
    >
      {props.InputsLabel}
    </div> 
  );
};

export const AddLeadInput = (props) => {
  const [val, setVal] = useState('');
  const onInputChange = (e) => {
    const { value } = e.target;
    if (isLetters(value)) {
      setVal(value);
    }
  };

  useEffect(() => {
    if(props.defautValue && props.defautValue !== null){
      setVal(props.defautValue);
    }
  }, [props]);
  
  return (
    <input
      type={props.InputType}
      name={props.InputName}
      placeholder={props.InputLabel}
      className="custom-input"
      required={props.Required}
      value={val}
      onChange={onInputChange}
      ref={props.inputRef}
      id={props.inputId}
    />
  );
};

export const IntlDropdown = (props) => {
  const [code, setCode] = useState(0);
  const [number, setNumber] = useState(0);  
  
  useEffect(() => { 
    if(props.defautValue && props.defautValue[1] !== null){
      setCode(props.defautValue[0]);
      setNumber(props.defautValue[1]);
    }
  }, [props]);
  
  return (
    <React.Fragment>
      <PhoneInput 
        autoFormat
        enableSearch
        // country="in"
        placeholder="000 0000 0000"
        preferredCountries={['in','ae','gb','us']}
        inputProps={{ 
          required: props.InputRequired,
          autoFocus: true
        }}
        // containerClass=""
        // inputClass=""
        // buttonClass=""
        // dropdownClass=""
        // searchClass=""
        containerStyle={{width: '100%'}}
        inputStyle={{width: '100%'}}
        // buttonStyle={{width: '100%'}}
        // dropdownStyle={{width: '100%'}}
        // searchStyle={{width: '100%'}}
        value={number ? code+number : '+91'}
        onChange={(value, data, event, formattedValue) => 
          setCode(data.dialCode)+setNumber(value.slice(data.dialCode.length))
        }
      />
      <input type="hidden" id={props.InputId[0]} ref={props.InputRef[0]} name={props.Inputname[0]} value={code} />
      <input type="hidden" id={props.InputId[1]} ref={props.InputRef[1]} name={props.Inputname[1]} value={number} />
    </React.Fragment>
  );
};

export const NumberInput = (props) => {
  const [val, setVal] = useState("");
  const handleChange = (e) => {
    const regex = props.InputDots ? /^[0-9.A-Za-z \b]+$/ : /^[0-9\b]+$/ ;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setVal(e.target.value);
    }
  };
  return (
    <React.Fragment>
      {props.InputDots === false ?
        <input
          type={props.InputType}
          name={props.InputName}
          placeholder={props.InputLabel}
          className="custom-input btm-border" 
          required={props.Required}
          style={{padding: '8px 0'}}
          value={val} 
          onChange={handleChange}
        />
      :
        <FormControl>
          <InputLabel htmlFor="component-outlined" sx={{lineHeight: '1', fontSize: '14px'}}>{props.InputLabel}</InputLabel>
          <OutlinedInput
            id="component-outlined"
            // defaultValue={val}
            className="custom-input" 
            label={props.InputLabel}
            type={props.InputType}
            name={props.InputName}
            required={props.Required}
            style={{padding: '0'}}
            value={val} 
            onChange={handleChange}
          />
          {/* <FormHelperText id="component-helper-text" sx={{color: 'red', mx: 0, width: '100%'}}>
          </FormHelperText> */}
        </FormControl>
      }
    </React.Fragment>
  );
};

export const InputEmail = (props) => {
  const isEmail = (str) => /^[0-9A-Za-z.@-]*$/.test(str);
  const [val, setVal] = useState("");
  const onInputChange = (e) => {
    const { value } = e.target;
    if (isEmail(value)) {
      setVal(value);
    }
  };

  useEffect(() => {
    if(props.defautValue && props.defautValue !== null){
      setVal(props.defautValue);
    } 
  }, [props]);
  
  return (
    <input
      type={props.InputType}
      name={props.InputName}
      placeholder={props.InputLabel}
      className="custom-input"
      required={props.Required}  
      value={val}
      onChange={onInputChange}
      ref={props.InputRef}
      id={props.InputId}
    />
  );
};

export const InputMessage = (props) => {
  const [message, setMessage] = useState('');
  const handleChange = event => {
    const regex = event.target.value.replace(/[^a-zA-Z0-9- ().,;:"'?*_@&\n]/gi, '');
    setMessage(regex);
  };
  
  useEffect(() => {
    if(props.defautValue && props.defautValue !== null){
      setMessage(props.defautValue);
    }
  }, [props]);

  return (
    <TextareaAutosize
      aria-label="empty textarea"
      placeholder="Enter Other Details"
      className="custom-textarea"
      style={{
        width: "100%", 
        border: "none",
        fontSize: '14px',
        color: '#303030',
        fontWeight: '400'
      }}
      minRows={3}
      name={props.inputName}
      value={message}
      onChange={handleChange}
    />
  );
};

export const InputAutocomplete = (props) => { 
  const [serviceType, setServiceType] = useState('');
  const [directValue, setDirectValue] = useState('');
  const [brokerValue, setBrokerValue] = useState('');
  const [projectType, setProjectType] = useState('');
  const [projectCategory, setProjectCategory] = useState('');
  const [inputValue, setInputValue] = useState('');
  
  useEffect(() => {
    if(props.sourcetype === 'Service Type' && props.defautValue){
      setServiceType(props.defautValue);   
    }else if(props.sourcetype === 'Project Type' && props.defautValue && props.defautValue !== 'null'){
      setProjectType(props.defautValue);
    }else if(props.sourcetype === 'Project Category' && props.defautValue && props.defautValue !== 'null'){
      setProjectCategory(props.defautValue);
    }
  }, [props]);

  return (
    <Autocomplete 
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="controllable-states-demo"
      options={
        props.sourcetype === 'Direct' ?
          props.directServices
        : props.sourcetype === 'Broker' ?
          props.brokerServices
        : props.services !== "Currencylist" ? 
          props.services 
        : currencylist
      } 
      renderInput={(params) => 
        <TextField {...params}  
          variant={props.inputLable ? "outlined" : "standard"}
          label={props.inputLable ? props.inputLable : null}
          name={props.inputname}
          className="inputautocomplete"
          required={props.inputRequired}
        />
      } 
      value={
        props.sourcetype === 'Service Type' ?
          serviceType ? serviceType : props.services[0]
        : props.sourcetype === 'Direct' ?
          directValue ? directValue : props.directServices[0]
        : props.sourcetype === 'Broker' ?
          brokerValue ? brokerValue : props.brokerServices[0]
        : props.sourcetype === 'Project Type' ?
          projectType ? projectType : props.services[0]
        : props.sourcetype === 'Project Category' ?
          projectCategory ? projectCategory : props.services[0]
        : props.services !== "Currencylist" ?
          props.defautValue ? props.defautValue : props.services[0]
        : currencylist[0]
      } 
      onChange={
        props.sourcetype === 'Service Type' ? 
          (event, newValue) => {setServiceType(newValue)} 
        : props.sourcetype === 'Direct' ? 
          (event, newValue) => {setDirectValue(newValue)} 
        : props.sourcetype === 'Broker' ?
          (event, newValue) => {setBrokerValue(newValue)} 
        : props.sourcetype === 'Project Type' ?
          (event, newValue) => {setProjectType(newValue)}   
        : props.sourcetype === 'Project Category' ?
          (event, newValue) => {setProjectCategory(newValue)}   
        : null
      } 
    />
  );
}; 

export const InputMultiAutocomplete = (props) => {
  const [value, setValue] = useState([]);
  
  useEffect(() => {
    if(props.sourcetype === 'Select Configuration' && props.defautValue && props.defautValue !== 'null'){
      let val = props.defautValue.split(',');
      setValue(
        val.map(item => ({
          title: item,
        })
      ));
    }
    if(props.sourcetype === 'Select Project' && props.defautValue && props.defautValue !== 'null'){
      let val = props.defautValue.split(',');
      setValue(
        val.map(item => ({
          title: item,
        })
      ));
    }
  }, [props]);
  
  return (
    <React.Fragment>
      <Autocomplete
        multiple
        limitTags={3}
        id="checkboxes-tags-demo"
        options={props.options}
        value={value}
        disableCloseOnSelect
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        )} 
        renderInput={(params) => (
          <TextField {...params} 
            label={props.inputLable}  
            className="InputMultiAutocomplete" 
            placeholder={props.inputLable} 
          />
        )}   
        onChange={(event, newValue) => { 
          setValue(newValue)
        }}
      />
      <input type='hidden' name={props.inputname} value={value?.map((key) => (key.title))} />
    </React.Fragment>
  );
}; 

export const InputCountryStateCity = (props) => {
  // Country.getAllCountries()[100] : default india
  const [selectedCountry, setSelectedCountry] = useState(null);
  // State?.getStatesOfCountry(selectedCountry?.isoCode)[20] : default maharashtra
  const [selectedState, setSelectedState] = useState(null);
  // City.getCitiesOfState(selectedState?.countryCode,selectedState?.isoCode)[341] : default mumbai
  const [selectedCity, setSelectedCity] = useState(null); 
  
  // validation
  const isLetter = (str) => /^[A-Za-z, ]*$/.test(str);
  const [val, setVal] = useState("");
  const onInputChange = (e) => {
    const { value } = e.target;
    if (isLetter(value)) {
      setVal(value);
    }
  };

  useEffect(() => {
    let countries = Country.getAllCountries();
    let states = State?.getStatesOfCountry(selectedCountry?.isoCode);
    let cities = City.getCitiesOfState(selectedState?.countryCode,selectedState?.isoCode);

    if(!selectedCountry && props.defautCountry){
      countries.map((cname, key) => (
        cname.name === props.defautCountry ?  
          setSelectedCountry(Country.getAllCountries()[key])
        : null 
      ))
    } 
    if(!selectedState && props.defautState){ 
      states.map((sname, key) => (
        sname.name === props.defautState ?  
          setSelectedState(State?.getStatesOfCountry(selectedCountry?.isoCode)[key])
        : null 
      ))
    }
    if(!selectedCity && props.defautCity){
      cities.map((cityname, key) => (
        cityname.name === props.defautCity ?  
          setSelectedCity(City.getCitiesOfState(selectedState?.countryCode,selectedState?.isoCode)[key])
        : null 
      ))
    }
    
    if(props.defautLocality){
      setVal(props.defautLocality);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, selectedCountry?.isoCode, selectedCountry, selectedState]);
  
  return ( 
    <React.Fragment>
      <Autocomplete 
        id="controllable-states-demo"
        options={Country.getAllCountries()} 
        getOptionLabel={(option) => option.name} 
        renderInput={(params) => 
          <TextField {...params}  
            variant="outlined"
            label="Select Country"
            name={props.inputNames[0]}
            className="inputautocomplete"
            // required={props.inputRequired}
          />
        }  
        value={selectedCountry}
        onChange={(event, newValue) => {
          setSelectedCountry(newValue); 
          if(selectedState){
            setSelectedState(null);
          }
          setVal('');
        }}  
      /> 
      {selectedCountry ? 
        <React.Fragment>
          <br />
          <Autocomplete 
            id="controllable-states-demo"
            options={State?.getStatesOfCountry(selectedCountry?.isoCode)} 
            getOptionLabel={(option) => option.name} 
            renderInput={(params) => 
              <TextField {...params}  
                variant="outlined"
                label="Select State"
                name={props.inputNames[1]}
                className="inputautocomplete" 
              />
            }  
            value={selectedState}
            onChange={(event, newValue) => {
              setSelectedState(newValue);
              if(selectedCity){
                setSelectedCity(null);
              }
              setVal('');
            }}  
          /> 
        </React.Fragment>
      : null}
      {selectedCountry && selectedState ?
        <React.Fragment>
          <br />
          <Autocomplete 
            id="controllable-states-demo"
            options={City.getCitiesOfState(
              selectedState?.countryCode,
              selectedState?.isoCode
            )} 
            getOptionLabel={(option) => option.name} 
            renderInput={(params) => 
              <TextField {...params}  
                variant="outlined"
                label="Select City"
                name={props.inputNames[2]}
                className="inputautocomplete" 
              />
            }  
            value={selectedCity}
            onChange={(event, newValue) => {
              setSelectedCity(newValue);
              if(val){
                setVal('');
              }
            }}  
          />  
        </React.Fragment>
      : null}
      {selectedCountry && selectedState && selectedCity ? 
        <React.Fragment>
          <br /> 
          <TextField 
            id="outlined-basic"
            label="Enter Locality"
            variant="outlined"
            className="custom-input"
            placeholder="Enter Locality"
            name={props.inputNames[3]}
            helperText="accepts: alphabets, space & coma only"
            value={val}
            onChange={onInputChange}
          />
        </React.Fragment>
      : null}
    </React.Fragment> 
  );
}

// min & max carpet
export const CarpetAreaInput = (props) => {  
  const [inputValue, setInputValue] = useState(props.defautUnitValue ? props.defautUnitValue : '');
  const [minArea, setMinArea] = useState('');
  const [maxArea, setMaxArea] = useState(''); 
  // const [minAberv, setMinAberv] = useState();

  const toWords = new ToWords({ 
    localeCode: 'en-IN',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: true,
      doNotAddOnly: true,
      currencyOptions: {
        name: minArea.includes('.') || maxArea.includes('.') ? null : inputValue,
        plural: minArea.includes('.') || maxArea.includes('.') ? null : inputValue,
        // symbol: '',
        fractionalUnit: {
          name: minArea.includes('.') || maxArea.includes('.') ? inputValue : null,
          plural: minArea.includes('.') || maxArea.includes('.') ? inputValue : null,
          // symbol: '',
        },
      }
    }
  });

  // const handleAberv = (e) => {
  //   let val = e.target.value;
  //   setMinAberv(val !== 'none' ? val : '');
  // } 

  useEffect(() => {    
    if(props.defautUnitValue && props.defautUnitValue !== 'null'){
      setInputValue(props.defautUnitValue);
    }
    if(props.defaultMinArea && props.defaultMinArea !== 'null'){
      setMinArea(props.defaultMinArea);
    }
    if(props.defaultMaxArea && props.defaultMaxArea !== 'null'){
      setMaxArea(props.defaultMaxArea);
    }
  }, [props]);

  return (
    <React.Fragment>
      <Autocomplete 
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={props.unitlist}
        renderInput={(params) => 
          <TextField {...params}  
            variant="outlined"
            label="Units"
            name={props.unitsname}
            className="InputMultiAutocomplete" 
            placeholder="Select Units" 
            required={minArea || maxArea ? true : false}
          />
        } 
        value={inputValue ? inputValue : props.unitlist[0]} 
        sx={{mb:1}}
      />

      {parseInt(minArea) > parseInt(maxArea) ?
        <InputErrorMessage errormsg="Min area cannot be greater than max area." />
      : parseInt(minArea) === parseInt(maxArea) ? 
        <InputErrorMessage errormsg="Min area & max area is same." />
      : null}

      {/* <Grid container>
        <Grid item={true} xs={9}> */}
          <CurrencyInput 
            id="input-example"
            name={props.minareaname}
            placeholder="Min Area (select k, m, b after price)"
            className="CarpetAreaInput"
            decimalsLimit={2}
            intlConfig={{ locale: 'en-IN', currency: 'INR' }}
            prefix={" "}
            decimalSeparator="."
            groupSeparator=","
            // defaultValue={0}
            // suffix=" %"
            value={minArea}  
            onValueChange={(value) => value ? setMinArea(value) : setMinArea('')}
            required={maxArea ? true : false}
            disableAbbreviations={false}
          />
        {/* </Grid> 
        <Grid item={true} xs={3} sx={{m:0, pl:1}}>
          <TextField
            id="outlined-select-currency"
            select
            label="Select"
            defaultValue="none"
            // helperText=""
            className="CarpetAreaInputAbbr"
            onChange={handleAberv}
          >
            {[{value: 'none', label: 'none',},{value: 'k', label: 'k',},{value: 'm', label: 'm',},{value: 'b', label: 'b',}].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid> */}

      <p className="ErrorInputHelper">
        {minArea ? toWords.convert(minArea ? minArea : 0, { currency: true })+"." : null}
      </p>

      {parseInt(maxArea) < parseInt(minArea) ?
        <InputErrorMessage errormsg="Max area cannot be lower than min area." />
      : parseInt(maxArea) === parseInt(minArea) ? 
        <InputErrorMessage errormsg="Max area & min area is same." />
      : null}

      <CurrencyInput
        style={{marginTop: '4px'}} 
        id="input-example"
        name={props.maxareaname}
        placeholder="Max Area (shortcut: tap k, m, b after number)"
        className="CarpetAreaInput"
        decimalsLimit={2}
        intlConfig={{ locale: 'en-IN', currency: 'INR' }}
        prefix={" "}
        decimalSeparator="."
        groupSeparator=","
        // defaultValue={0}
        // suffix=" %"
        value={maxArea}  
        onValueChange={(value) => value ? setMaxArea(value) : setMaxArea('')}
        required={minArea ? true : false}
        disableAbbreviations={false}
      />
      <p className="ErrorInputHelper">
        {maxArea ? toWords.convert(maxArea ? maxArea : 0, { currency: true })+"." : null}
      </p>
    </React.Fragment>
  );
}

// min & max price
export const PricingInput = (props) => { 
  const [inputValue, setInputValue] = useState('');
  const [currency, setCurrency] = useState();
  const [prefix, setPrefix] = useState();
  const [locale, setLocale] = useState();
  const [minNum, setMinNum] = useState('');
  const [maxNum, setMaxNum] = useState(''); 
  // const [defaultNewCurrency, setDefaultNewCurrency] = useState([]);

  const toWords = new ToWords({ 
    localeCode: locale ? locale : CurrencyListLocales[0].locale,
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: true,
      doNotAddOnly: true,
      // currencyOptions: {
      //   name: 'Rupee',
      //   plural: 'Rupees',
      //   symbol: '₹',
      //   fractionalUnit: {
      //     name: 'Paisa',
      //     plural: 'Paise',
      //     symbol: '',
      //   },
      // }
    }
  });
   
  useEffect(() => { 
    setCurrency(CurrencyListLocales[0].code);
    setPrefix(CurrencyListLocales[0].symbol);
    setLocale(CurrencyListLocales[0].locale); 

    // if(props.defaultCurrency){
    //   let oldcurrency = props.defaultCurrency.split('~');
    //   let symbole = decode(oldcurrency[1], {mode: 'extensive', level: 'html5'});
    //   let newcurrency = [{'name':oldcurrency[0], 'symbol':symbole, 'code':oldcurrency[2], 'locale':oldcurrency[3]}];
      // setDefaultNewCurrency(...newcurrency);
      // newcurrency.join('~')
    // }
    
    if(props.defaultMinPrice && props.defaultMinPrice !== 'null'){
      setMinNum(props.defaultMinPrice);
    }
    if(props.defaultMaxPrice && props.defaultMaxPrice !== 'null'){
      setMaxNum(props.defaultMaxPrice);
    }
    
  }, [props]);
     
  return (
    <React.Fragment>
      <Autocomplete 
        limitTags={1}
        id="checkboxes-tags-demo"
        options={CurrencyListLocales} 
        getOptionLabel={(option) => option.name+' ~ '+option.symbol+' ~ '+option.code+' ~ '+option.locale}
        isOptionEqualToValue={
          (option, value) => option.name === value.name && option.symbol === value.symbol && option.code === value.code && option.locale === value.locale
        } 
        renderInput={(params) => (
          <TextField {...params} 
            label="Currency" 
            className="InputMultiAutocomplete" 
            placeholder="Select Currency" 
            name={props.currencyname}
            required={minNum || maxNum ? true : false}
          />
        )}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);  
        }} 
        defaultValue={CurrencyListLocales[0]}
        // value={}
        onChange={(event, newValue) => {
          if(newValue === null){ 
            setCurrency(CurrencyListLocales[0].code);
            setPrefix(CurrencyListLocales[0].symbol);
            setLocale(CurrencyListLocales[0].locale);
            setMinNum('');
            setMaxNum('');
          }else{
            setCurrency(newValue.code);
            setPrefix(newValue.symbol);
            setLocale(newValue.locale);
            setMinNum(props.defaultMinPrice ? props.defaultMinPrice : '');
            setMaxNum(props.defaultMaxPrice ? props.defaultMaxPrice : '');
          }
        }}
        sx={{mb:1}} 
      />

      {parseInt(minNum) > parseInt(maxNum) ?
        <InputErrorMessage errormsg="Min price cannot be greater than max price." />
      : parseInt(minNum) === parseInt(maxNum) ? 
        <InputErrorMessage errormsg="Min price & max price is same." /> 
      : null}

      <CurrencyInput 
        id="input-example"
        name={props.minpricename}
        placeholder="Min Price (shortcut: tap k, m, b after price)"
        className="PricingInput"
        decimalsLimit={2}
        intlConfig={{ locale: locale, currency: currency }}
        prefix={prefix+" "}
        decimalSeparator="."
        groupSeparator=","
        // defaultValue={0}
        // suffix=" %"
        value={minNum}  
        onValueChange={(value) => value ? setMinNum(value) : setMinNum('')}
        required={maxNum ? true : false}
        disableAbbreviations={false}
      />
      <p className="ErrorInputHelper">
        {minNum ? toWords.convert(minNum ? minNum : 0, { currency: true })+"." : null}
      </p>

      {parseInt(maxNum) < parseInt(minNum) ? 
        <InputErrorMessage errormsg="Max price cannot be lower than min price." /> 
      : parseInt(maxNum) === parseInt(minNum) ?
        <InputErrorMessage errormsg="Max price & min price is same." /> 
      : null}

      <CurrencyInput
        style={{marginTop: '4px'}}
        id="input-example"
        name={props.maxpricename}
        placeholder="Max Price (shortcut: tap k, m, b after price)"
        className="PricingInput"
        decimalsLimit={2}  
        intlConfig={{ locale: locale, currency: currency }}
        prefix={prefix+" "}
        // suffix=" %"
        decimalSeparator="."
        groupSeparator="," 
        // defaultValue={0}
        value={maxNum} 
        onValueChange={(value) => value ? setMaxNum(value) : setMaxNum('')}
        required={minNum ? true : false}
        disableAbbreviations={false}
      />
      <p className="ErrorInputHelper"> 
        {maxNum ? toWords.convert(maxNum ? maxNum : 0, { currency: true })+"." : null}
      </p>
    </React.Fragment>
  );
}

// 
const currencylist = [
  "Indian Rupee - ₹",
  "Australian Dollar - $",
  "United Arab Emirates Dirham - إ.د",
  "Afghan Afghani - ؋",
  "Albanian Lek - Lek",
  "Algerian Dinar - دج",
  "Angolan Kwanza - Kz",
  "Argentine Peso - $",
  "Armenian Dram - ֏",
  "Aruban Florin - ƒ",
  "Azerbaijani Manat - m",
  "Bahamian Dollar - B$",
  "Bahraini Dinar - .د.ب",
  "Bangladeshi Taka - ৳",
  "Barbadian Dollar - Bds$",
  "Belarusian Ruble - Br",
  "Belgian Franc - fr",
  "Belize Dollar - $",
  "Bermudan Dollar - $",
  "Bhutanese Ngultrum - Nu.",
  "Bitcoin - ฿",
  "Bolivian Boliviano - Bs.",
  "Bosnia-Herzegovina Convertible Mark - KM",
  "Botswanan Pula - P",
  "Brazilian Real - R$",
  "British Pound Sterling - £",
  "Brunei Dollar - B$",
  "Bulgarian Lev - Лв.",
  "Burundian Franc - FBu",
  "Cambodian Riel - KHR",
  "Canadian Dollar - $",
  "Cape Verdean Escudo - $",
  "Cayman Islands Dollar - $",
  "CFA Franc BCEAO - CFA",
  "CFA Franc BEAC - FCFA",
  "CFP Franc - ₣",
  "Chilean Peso - $",
  "Chinese Yuan - ¥",
  "Colombian Peso - $",
  "Comorian Franc - CF",
  "Congolese Franc - FC",
  "Costa Rican ColÃ³n - ₡",
  "Croatian Kuna - kn",
  "Cuban Convertible Peso - $, CUC",
  "Czech Republic Koruna - Kč",
  "Danish Krone - Kr.",
  "Djiboutian Franc - Fdj",
  "Dominican Peso - $",
  "East Caribbean Dollar - $",
  "Egyptian Pound - ج.م",
  "Eritrean Nakfa - Nfk",
  "Estonian Kroon - kr",
  "Ethiopian Birr - Nkf",
  "Euro - €",
  "Falkland Islands Pound - £",
  "Fijian Dollar - FJ$",
  "Gambian Dalasi - D",
  "Georgian Lari - ლ",
  "German Mark - DM",
  "Ghanaian Cedi - GH₵",
  "Gibraltar Pound - £",
  "Greek Drachma - ₯, Δρχ, Δρ",
  "Guatemalan Quetzal - Q",
  "Guinean Franc - FG",
  "Guyanaese Dollar - $",
  "Haitian Gourde - G",
  "Honduran Lempira - L",
  "Hong Kong Dollar - $",
  "Hungarian Forint - Ft",
  "Icelandic KrÃ³na - kr",
  "Indonesian Rupiah - Rp",
  "Iranian Rial - ﷼",
  "Iraqi Dinar - د.ع",
  "Israeli New Sheqel - ₪",
  "Italian Lira - L,£",
  "Jamaican Dollar - J$",
  "Japanese Yen - ¥",
  "Jordanian Dinar - ا.د",
  "Kazakhstani Tenge - лв",
  "Kenyan Shilling - KSh",
  "Kuwaiti Dinar - ك.د",
  "Kyrgystani Som - лв",
  "Laotian Kip - ₭",
  "Latvian Lats - Ls",
  "Lebanese Pound - £",
  "Lesotho Loti - L",
  "Liberian Dollar - $",
  "Libyan Dinar - د.ل",
  "Lithuanian Litas - Lt",
  "Macanese Pataca - $",
  "Macedonian Denar - ден",
  "Malagasy Ariary - Ar",
  "Malawian Kwacha - MK",
  "Malaysian Ringgit - RM",
  "Maldivian Rufiyaa - Rf",
  "Mauritanian Ouguiya - MRU",
  "Mauritian Rupee - ₨",
  "Mexican Peso - $",
  "Moldovan Leu - L",
  "Mongolian Tugrik - ₮",
  "Moroccan Dirham - MAD",
  "Mozambican Metical - MT",
  "Myanmar Kyat - K",
  "Namibian Dollar - $",
  "Nepalese Rupee - ₨",
  "Netherlands Antillean Guilder - ƒ",
  "New Taiwan Dollar - $",
  "New Zealand Dollar - $",
  "Nicaraguan CÃ³rdoba - C$",
  "Nigerian Naira - ₦",
  "North Korean Won - ₩",
  "Norwegian Krone - kr",
  "Omani Rial - .ع.ر",
  "Pakistani Rupee - ₨",
  "Panamanian Balboa - B/.",
  "Papua New Guinean Kina - K",
  "Paraguayan Guarani - ₲",
  "Peruvian Nuevo Sol - S/.",
  "Philippine Peso - ₱",
  "Polish Zloty - zł",
  "Qatari Rial - ق.ر",
  "Romanian Leu - lei",
  "Russian Ruble - ₽",
  "Rwandan Franc - FRw",
  "Salvadoran ColÃ³n - ₡",
  "Samoan Tala - SAT",
  "Saudi Riyal - ﷼",
  "Serbian Dinar - din",
  "Seychellois Rupee - SRe",
  "Sierra Leonean Leone - Le",
  "Singapore Dollar - $",
  "Slovak Koruna - Sk",
  "Solomon Islands Dollar - Si$",
  "Somali Shilling - Sh.so.",
  "South African Rand - R",
  "South Korean Won - ₩",
  "Special Drawing Rights - SDR",
  "Sri Lankan Rupee - Rs",
  "St. Helena Pound - £",
  "Sudanese Pound - .س.ج",
  "Surinamese Dollar - $",
  "Swazi Lilangeni - E",
  "Swedish Krona - kr",
  "Swiss Franc - CHf",
  "Syrian Pound - LS",
  "São Tomé and Príncipe Dobra - Db",
  "Tajikistani Somoni - SM",
  "Tanzanian Shilling - TSh",
  "Thai Baht - ฿",
  "Tongan pa'anga - $",
  "Trinidad & Tobago Dollar - $",
  "Tunisian Dinar - ت.د",
  "Turkish Lira - ₺",
  "Turkmenistani Manat - T",
  "Ugandan Shilling - USh",
  "Ukrainian Hryvnia - ₴",
  "Uruguayan Peso - $",
  "US Dollar - $",
  "Uzbekistan Som - лв",
  "Vanuatu Vatu - VT",
  "Venezuelan BolÃ­var - Bs",
  "Vietnamese Dong - ₫",
  "Yemeni Rial - ﷼",
  "Zambian Kwacha - ZK"
];